import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box} from "@chakra-ui/react";
import TabTime from "../../tools/TabTime";
import {HeaderSectionBig} from "../../HeaderSection";
import DataLoadContext from "../../../../context/DataLoadContext";
import {getDataPersonsSummary, getDataTopFullVocabulary} from "../../../api/getData";
import {LOG} from "../../../tools";
import SummaryTableSimple from "./SummaryTableSimple";
import PersonalizedContentContext from "../../../../context/PersonalizedContent";
import {LIST_ALL_PERSON} from "../../../tag/tools";


const SectionSummaryPersons = ({}) =>{

    const [indexTimeValue,setIndexTimeValue] = useState(0)
    const listTimeValue = [{id:"4",n:" 4 heures"},{id:"24",n:" 24 heures"}, {id: "168", n: "1 semaine"}]
    const timeTopValue = listTimeValue[indexTimeValue]["id"]
    const listPersonJson = LIST_ALL_PERSON
    const {lastUpdate} = useContext(DataLoadContext);

    const [summaryData, setSummaryData] = useState({})

    const lastUpdatePersons = lastUpdate.hasOwnProperty("Persons")?lastUpdate["Persons"]:null
    useEffect(() => {
        if(lastUpdatePersons!=null){
            getDataPersonsSummary(timeTopValue ,lastUpdatePersons).then((d) =>{
            setSummaryData(d)
            LOG("setSummaryData",d)
    });
        }

  }, [lastUpdatePersons,timeTopValue]);

    const {jsonTagVisible} = useContext(PersonalizedContentContext);
    const tagType = "P"
    const objectSetTagVisible = useMemo(()=>{
        return jsonTagVisible.hasOwnProperty(tagType)?
                        (jsonTagVisible[tagType].size>0?jsonTagVisible[tagType]:new Set(listPersonJson.map(p => p.id)))
                                :new Set(listPersonJson.map(p => p.id))
    }, [jsonTagVisible,listPersonJson])

    const listPersonJsonShown = useMemo(()=>{
        return listPersonJson.filter((personJson)=> objectSetTagVisible.has(personJson.id))
    }, [listPersonJson,objectSetTagVisible])

    return <Box>
        <HeaderSectionBig text={"Top Personnalités"}  idName={"top_personnalite"}/>
            <TabTime listTimeValue={listTimeValue} indexTimeValue={indexTimeValue}
       onChangeIndex={(index) => setIndexTimeValue(index)} />

        <SummaryTableSimple listPersonJsonShown={listPersonJsonShown} jsonDataFromId={summaryData}/>

    </Box>
}

export default  SectionSummaryPersons