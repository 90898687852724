import {Box, Flex, Spinner} from "@chakra-ui/react";
import React, {useContext, useEffect, useMemo, useState} from "react";
import TopItem from "./TopItem";
import {HeaderSectionBig, HeaderSectionBigMain} from "../HeaderSection";
import Masonry from 'react-masonry-css'
import './TicTweet/masonry_tweet.css'
import {LOG} from "../../tools";
import DataLoadContext from "../../../context/DataLoadContext";
import {getDataTopTweets, getDataTopVocabulary} from "../../api/getData";
import {motion} from "framer-motion";
import TabTime from "../tools/TabTime";
import {useInView} from "react-intersection-observer";

const breakpointColumnsTweets = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};

const MotionFlex = motion(Flex)
const TopAreaContent = ({typeItem})=>{
    const [indexTimeValue,setIndexTimeValue] = useState(0)
    const listTimeValue = [{id:"4",n:" 4 heures"},{id:"24",n:" 24 heures"}, {id: "168", n: "1 semaine"}]

    const timeTopValue = listTimeValue[indexTimeValue]["id"]
    const [listItem,setListItem] = useState([])
    const {lastUpdate} = useContext(DataLoadContext);
    const lastUpdateTop = lastUpdate.hasOwnProperty("Top")?lastUpdate["Top"]:null

    useEffect(() => {
        if(lastUpdateTop!=null){
            switch (typeItem) {
                case "tweet":
                    getDataTopTweets(timeTopValue,lastUpdateTop).then((d) =>{
                            LOG("getDataTopTweets",d)
                            setListItem(d)
                    });
                    return
                case "vocab":
                    getDataTopVocabulary(timeTopValue,lastUpdateTop).then((d) =>{
                            LOG("getDataTopVocabulary",d)
                            setListItem(d)
                });
                    return;
                default:
                    return
            }
        }
  }, [lastUpdateTop,timeTopValue]);


    const content = useMemo( () => {
        if (listItem.length ==0 ){
            return <Flex justifyContent={"center"} alignItems={"center"} flexDirection={"column"} height={200}>
            <Spinner color="text.100" />
        </Flex>
        }
        switch (typeItem) {
            case "tweet":
                return <Masonry
                          breakpointCols={breakpointColumnsTweets}
                          className="my-masonry-grid"
                          columnClassName="my-masonry-grid_column">
                          {listItem.map((jsonItem,i)=>{
                            return <TopItem key={i} timeTopValue={timeTopValue} typeItem={typeItem} jsonItem={jsonItem} rank={i+1}/>
                        })}
                        </Masonry>
            case "vocab":
                return <MotionFlex pt={[1,2]} flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"flex-start"} minHeight={"150px"}>
                    {listItem.map((jsonItem,i)=>{
                            return <TopItem key={jsonItem.n} timeTopValue={timeTopValue} typeItem={typeItem} jsonItem={jsonItem} rank={i+1}/>
                        })}
                 </MotionFlex>
        }
    },[typeItem,listItem])
    const msgNote = useMemo( () => {
        switch (typeItem) {
            case "tweet":
                return "Cliquez sur un tweet pour l'afficher"
            case "vocab":
                return  "Cliquez sur un mot pour plus d'informations"
        }
    },[typeItem,listItem])


    const textInfo = useMemo(()=>{
        switch (typeItem) {
            case "tweet":
                return  <Box>Liste des Tweets les plus tendances</Box>
            case "vocab":
                return <Box>Liste des hashtags les plus tendances</Box>
            default:
                return <Box>Liste des hashtags et mots clés les plus tendances</Box>
        }
    },[typeItem])

    return <Box p={[1,1,1]}>

        <Box p={["1px",2,2]} >
            <Box color={"text.200"}>{textInfo}</Box>
       <TabTime listTimeValue={listTimeValue} indexTimeValue={indexTimeValue}
       onChangeIndex={(index) => setIndexTimeValue(index)} />
        {content}
            <Box fontStyle={"italic"} color={"text.200"}>{msgNote}</Box>
        </Box>

    </Box>
}

const TopArea = ({title,typeItem}) => {
  const { ref, inView, entry } = useInView({
      triggerOnce: true ,
    threshold: 0,
  });
  return (
    <Box ref={ref}>
        <HeaderSectionBig text={title} idName={`top_${typeItem}`}/>
        {inView && <TopAreaContent  typeItem={typeItem}/>}
        {(!inView) && <Flex justifyContent={"center"} height={800}>
            <Spinner color="text.100" />
        </Flex>}
    </Box>
  );
};



export default  TopArea