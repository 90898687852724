import {Box, Flex, Image, Spinner} from "@chakra-ui/react";

import React, {useContext, useEffect, useMemo, useState} from "react";
import {getUrlImgWordCloudTopic} from "../../../api/getData";
import {LOG} from "../../../tools";
import {motion} from "framer-motion";
import {getNameFromTagId} from "../../../tag/tools";

const MotionBox = motion(Box)
const WordCloudTopic = ({tagIdTopic}) =>{
    // TODO ONLY SHOW IMG
    const nameTopic = useMemo(()=>getNameFromTagId(tagIdTopic),[tagIdTopic])
    const urlImg = getUrlImgWordCloudTopic(tagIdTopic,new Date().valueOf())
    const Loader = <Box width={'100%'} pb={"100%"} height={0} position={"relative"}>
            <Flex justifyContent={"center"} alignItems={"center"} width={'100%'} position={"absolute"} height={"100%"}><Spinner  size='xl' color="text.100" /></Flex></Box>

    return <Flex  alignItems={"center"} m={2} fontFamily={"heading"} flexWrap={"wrap"}>
        <Box width={["100%","100%","50%"]} textAlign={"center"} fontWeight={"bold"} fontSize={"22px"}>
             <Box ><Box as={"span"} p={2} borderRadius={"4px"}
                        borderColor={"text.300"} borderStyle={"solid"} borderWidth={"1px"}
                         color={"secondary.500"} fontFamily={"heading"}>{nameTopic}</Box></Box>
            <Box color={"text.300"} mt={2}>Vocabulaire Significatif</Box>
            <Box fontSize={"14px"}>3 dernières semaines</Box>

        </Box>
        <Box width={["100%","100%","50%"]}

                   p={[1,2,3]}
        >
            <Box
                 maxWidth={"100%"} width={"500px"}
                       boxShadow={"rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;"}

                       p={"3px"} bg={"#232119"}  borderRadius={"4px"} position={"relative"}>
            <Image src={urlImg} alt='vocabulaire' fallback={Loader} />
        </Box>
        </Box>
    </Flex>
}

export default WordCloudTopic