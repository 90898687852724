
import React, {useState} from "react";
import {Tooltip} from "@chakra-ui/react";
import {InfoIcon} from "@chakra-ui/icons";


const TouchFriendlyTooltip =({ label }) => {
    const [isLabelOpen, setIsLabelOpen] = useState(false)

    return (
        <Tooltip label={label} isOpen={isLabelOpen}>
            <InfoIcon ml={1} mb={'0px'} p={"1px"} color={"text.50"} opacity={0.6}
            alt={label}
                onMouseEnter={() => setIsLabelOpen(true)}
                onMouseLeave={() => setIsLabelOpen(false)}
                onClick={() => setIsLabelOpen(true)}
            />
        </Tooltip>
    )
}

export default TouchFriendlyTooltip