import * as React from "react"
import {LOG} from "../../tools";
import {useMemo, useState} from "react";
import {TweetToken} from "./TweetToken";
import {TwitterTweetEmbed} from "react-twitter-embed";
import {SColorBG} from "../../../gatsby-plugin-theme-ui-old";

import {AnimatePresence, motion} from "framer-motion"
import {Box, useMediaQuery} from "@chakra-ui/react";
import {ViewIcon} from "@chakra-ui/icons"
const MotionBox = motion(Box)
const options = { year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit',minute:'2-digit' };
export const TweetCardTop = ({tweetFrontData,listHighlight})=> {
    const {id,aun,mu,nbr} = tweetFrontData
    const dateTweet = new Date(tweetFrontData.date)
    const dateTweetStr = useMemo(()=>{
        return dateTweet.toLocaleDateString("fr-FR", options)
    },[dateTweet])

    const [isHover,setIsHover] = useState(false)
    const [tweetIsMissing,setTweetIsMissing] = useState(false)
        const [isLowerThan580] = useMediaQuery("(max-width: 580px)")
    const listToken = tweetFrontData.lt
    const authorUserName = aun?aun:"unknown"
   const [showEmbed,setShowEmbed] =useState(false)

    return <MotionBox sx={{maxWidth:'100%',
         borderRadius:'3px',p:0,pb:0 }}
                onMouseEnter={()=>setIsHover(true)} onMouseLeave={()=>setIsHover(false)}
                animate={isHover?"hover":"default"}
                variants={{hover:{backgroundColor:SColorBG[900]},default:{backgroundColor:SColorBG[500]} }}
    >
        <Box sx={{lineHeight: 1}}>
            <Box sx={{display: 'flex',pl:1,pr:1, justifyContent: 'space-between'}}>
                <div>
                    <Box sx={{fontWeight: 'bold'}}>@{authorUserName}</Box>
                    <Box sx={{color: 'grey', fontSize: 12}}>{dateTweetStr}</Box>
                </div>
                <Box sx={{textAlign: 'center'}}>
                    <Box sx={{fontWeight: 'bold'}}>{nbr}</Box>
                    <Box sx={{color: 'grey', fontSize: 12}}>retweets</Box>
                </Box>
            </Box>
            {!showEmbed &&
            <Box sx={{lineHeight: 1.2, m: 1,borderRadius:5,borderColor:'text.50',borderWidth:'1px',borderStyle:'solid',
                p:1,pl:2, fontSize: 15,bg:"white"}}>{listToken.map((token, i) => <TweetToken key={i}
                                                                                                     token={token}
                                                                                                 listHighlight={listHighlight}/>)}</Box>
            }
            </Box>

            <motion.div
            sx={{minHeight:150}}
            initial={{ height:150}}
            animate={{height:'auto'}}
        >
                <AnimatePresence>
        {showEmbed &&

        <TwitterTweetEmbed
            tweetId={`${id}`}
            options={{"theme": "blue", "width": '400', 'align': 'center'}}
            onLoad={tweet => {
                if(!tweet){
                    setTweetIsMissing(true);
                }
            }}
        />
       }
       </AnimatePresence>
        </motion.div>

        <Box sx={{position:'relative',height:'20px'}}>
            <AnimatePresence>
                {(!showEmbed && (isHover | isLowerThan580 ))   &&
                <MotionBox onClick={()=>setShowEmbed(true)}
                            sx={{position:'absolute',right: '100px',cursor:'pointer',color:'text.100'}}
                            initial={{ opacity: 0,y:-12 }}
                            animate={{ opacity: 1,y:-5 }}
                            exit={{ opacity: 0,y:-12 }}
                ><ViewIcon mr={1} mb={'1px'} color={"text.50"}/>Afficher le tweet</MotionBox>}
            </AnimatePresence>

        </Box>
    </MotionBox>
}
