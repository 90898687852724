import {Box, Flex, Heading, useMediaQuery} from "@chakra-ui/react";
import * as React from "react";
import {SColorText} from "../../gatsby-plugin-theme-ui-old";
import {motion} from "framer-motion";
//const dateElection = new Date("05/17/2022")
const options = { year: 'numeric', month: 'long', day: 'numeric' }; //, hour:'2-digit',minute:'2-digit'

const MotionBox = motion(Box)
const HeaderMainPage = ({})=>{
    const [isLowerThan680] = useMediaQuery("(max-width: 680px)")

     const dateNow = new Date()
    const dateNowStr = dateNow.toLocaleDateString("fr-FR", options)

    //const Difference_In_Time = dateElection.getTime() - dateNow.getTime();

    // To calculate the no. of days between two dates
    //const  Difference_In_Days = Math.trunc(Difference_In_Time / (1000 * 3600 * 24));
    const colorBorder = SColorText[100]

    return <MotionBox
           variants={{
                    low:{opacity:0},
                    expand:{opacity:1}
        }}
        sx={{pt:'1px',pb:'1px', borderTop:`solid 2px ${colorBorder}`,
                borderBottom:`solid 2px ${colorBorder}`}}>

            <Flex sx={{p:'4px 4px',color:'text.100',fontWeight:'body', justifyContent:'space-between',
                borderTop:`solid 1px ${colorBorder}`, alignItems:"center",
                borderBottom:`solid 1px ${colorBorder}`
            }}>
                 {!isLowerThan680 &&<Box sx={{width: '200px', mr: '4px', p:1}}>#LaVoixDesElections</Box>}
            <Heading as={"h2"} fontSize={["16px","20px"]} sx={{mr:'4px', p:1}} fontWeight={"body"}>Edition Politique</Heading>
             <Box sx={{textTransform: 'uppercase',textAlign:"right",width:"200px", mr: '0px', p:1}}>{dateNowStr}</Box>
            </Flex>

        </MotionBox>



}

export default HeaderMainPage