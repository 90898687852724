
import React from "react";
import {Box} from "@chakra-ui/react";
import {HeaderSectionBig} from "../HeaderSection";
import GraphEvolTopics from "./GraphEvolTopics";


const SectionTopicsSummary = ({})=> {

    return <Box>

        <HeaderSectionBig text={"Évolution du poids des grandes thématiques"} idName={"thematique_summary"}/>
        <GraphEvolTopics/>
    </Box>
}
export default SectionTopicsSummary