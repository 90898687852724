import {Box, Flex, Heading} from "@chakra-ui/react";
import React, {useMemo} from "react";


const CDI_word = ({data}) => {

    return <Flex height={"100%"} justifyContent={"center"} flexDirection={"column"} m={1} position={"relative"}>
        <Box sx={{color: 'text.50', fontSize: 12 , textAlign:'right' ,position:"absolute",right:0,top:0}}>(4 dernières heures)</Box>
        {data.map((w, i) =>
            <Flex key={i} justifyContent={"flex-start"} alignItems={"center"} m={1} p={1} mr={0} pr={0}>
        <Box fontSize={"22px"} fontWeight={"bold"} color={"text.200"} p={1}>#{i+1}.</Box>
        <Box p={1} pr={0} fontWeight={"bold"} fontSize={"18px"} maxWidth={"calc(100% - 40px)"}  textTransform={"uppercase"}>{w}</Box>
    </Flex>)}
    </Flex>

}

export default CDI_word