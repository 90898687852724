import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box, Flex} from "@chakra-ui/react";
import TabTime from "../../tools/TabTime";
import {HeaderSectionBig} from "../../HeaderSection";
import DataLoadContext from "../../../../context/DataLoadContext";
import { getDataSummaryVocabs} from "../../../api/getData";
import {LOG} from "../../../tools";
import PersonalizedContentContext from "../../../../context/PersonalizedContent";
import {LIST_ALL_PERSON, LIST_ALL_VOCAB} from "../../../tag/tools";
import ErrorBoundary from "../../../tools/ErrorBoundary";
import RadarArea from "./RadarArea";
import DetailByVocab from "./DetailByVocab";
import TagMenuSelect from "../../../tag/components/TagMenuSelect";


const SectionVocabsPersons = ({}) =>{

    const [indexTimeValue,setIndexTimeValue] = useState(0)
    const listTimeValue = [{id: "504", n: "3 semaines"}]
    const timeTopValue = listTimeValue[indexTimeValue]["id"]
    const listPersonJson = LIST_ALL_PERSON

    const [evolData, setEvolData] = useState({})
        const [jsonContentSummaryByPersonTag, setJsonContentSummaryByPersonTag] = useState({})
        const [jsonContentByVocabtag, setJsonContentByVocabtag] = useState({})
    const {lastUpdate} = useContext(DataLoadContext);
    const lastUpdateTagTopics = lastUpdate.hasOwnProperty("TagTopics")?lastUpdate["TagTopics"]:null
    useEffect(() => {
        if(lastUpdateTagTopics!=null){
            getDataSummaryVocabs(timeTopValue ,lastUpdateTagTopics).then((d) =>{
            setEvolData(d)
                /* { d: {1:{d:[]}, 2:... },  sd: "2021xxxxx"} */
            LOG("getDataSummaryVocabs",d)
                    setJsonContentSummaryByPersonTag( d["p"])
                    setJsonContentByVocabtag( d["s"])
                //TODO : ADD  d["v"] -> json de vocab.
    });}

  }, [lastUpdateTagTopics,timeTopValue]);

    const {jsonTagVisible} = useContext(PersonalizedContentContext);
    const tagType = "P"
    const objectSetTagVisible = useMemo(()=>{
        return jsonTagVisible.hasOwnProperty(tagType)?
                        (jsonTagVisible[tagType].size>0?jsonTagVisible[tagType]:new Set(listPersonJson.map(p => p.id)))
                                :new Set(listPersonJson.map(p => p.id))
    }, [jsonTagVisible,listPersonJson])
    const listPersonJsonShown = useMemo(()=>{
        return listPersonJson.filter((personJson)=> objectSetTagVisible.has(personJson.id))
    }, [listPersonJson,objectSetTagVisible])

    const listContentVocab = useMemo(()=>{
        return listPersonJsonShown.filter((tagJson)=>jsonContentSummaryByPersonTag.hasOwnProperty(tagJson.id))
            .map((tagJson) => {
                const tagId = tagJson.id
            return {d:jsonContentSummaryByPersonTag[tagId]["r"],id:tagId}
        })
    },[jsonContentSummaryByPersonTag,listPersonJsonShown])


    const jsonContentByVocabtagFiltered = useMemo(()=>{
        let jsonContentTmp = {...jsonContentByVocabtag}
        Object.keys(jsonContentByVocabtag).map((v_id)=>{
                jsonContentTmp[v_id]["l"] = jsonContentTmp[v_id]["l"].filter((data)=>objectSetTagVisible.has(data[0].toString()))

            })
        return jsonContentTmp

    },[jsonContentByVocabtag,listPersonJsonShown])

    return <Box>
        <HeaderSectionBig text={"Comparaison des sujets abordés par les Personnalités"} idName={"thematique_comparaison"}/>
       {/*     <TabTime listTimeValue={listTimeValue} indexTimeValue={indexTimeValue}*/}
       {/*onChangeIndex={(index) => setIndexTimeValue(index)} />*/}
            <Flex justifyContent={"flex-end"} alignItems={"center"} mt={5} p={2} pb={0} flexWrap={"wrap"}>
            <TagMenuSelect listTag={listPersonJson}  tagType={"P"} />
        </Flex>
        <ErrorBoundary><RadarArea listContentVocab={listContentVocab}/> </ErrorBoundary>


        <ErrorBoundary><DetailByVocab jsonContentByVocabtag={jsonContentByVocabtagFiltered} /> </ErrorBoundary>



    </Box>
}

export default  SectionVocabsPersons