
import React, {useContext, useEffect, useMemo, useState} from "react";
import {Avatar, Box, Flex, Heading, Stat, StatArrow, StatHelpText, StatNumber} from "@chakra-ui/react";
import {getNameFromTagId, getUrlImgFromTagId} from "../../../tag/tools";
import {LOG} from "../../../tools";
import {motion} from "framer-motion";

const MotionBox = motion(Box)
const MotionFlex = motion(Flex)
const MotionAvatar = motion(Avatar)

const VocabPersonItem = ({tagId,propValue,evolValue}) =>{

    const propStr = Math.round(propValue/10)/10
    const evolStr = `${evolValue>=0?"+":""}${(Math.round(evolValue/10)/10).toString()}`
    const name = getNameFromTagId(tagId)
        const urlImg = getUrlImgFromTagId(tagId, 50)
    return <MotionFlex minWidth={100} animate={"default"} height={"100%"} p={2} m={2} flexDirection={"column"} alignItems={"center"} justifyContent={"flex-end"}>
        <MotionAvatar
            variants={{
                hover: {filter: 'grayscale(20%)', opacity: 1},
                default: {filter: 'grayscale(20%)', opacity: 1}
            }} src={urlImg} width={50}/>
        <Box mt={1} height={ `${Math.round(Math.min(propValue/30,70))}px`} width={"10px"} bg={"text.100"} />
        <Stat borderRadius={"3px"} borderStyle={"solid"} borderWidth={"1px"} borderColor={"text.50"} width={"100%"} textAlign={"center"}>
            <StatNumber>{propStr} %</StatNumber>
        <StatHelpText color={"text.300"} fontWeight={"bold"} pt={0} mb={0}><StatArrow type={evolValue>=0?'increase':'decrease'}  />{evolStr}</StatHelpText>
        <StatHelpText p={"1px"} height={50} display={"flex"} flexDirection={"column"} justifyContent={"center"}>{name}</StatHelpText>
        </Stat></MotionFlex>
}


export default VocabPersonItem