
import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box, Flex, Heading, Stat, StatArrow, StatHelpText, StatNumber, Text, Tooltip} from "@chakra-ui/react";
import {getNameFromTagId} from "../../../tag/tools";
import {LOG} from "../../../tools";
import VocabPersonItem from "./VocabPersonItem";
import {motion} from "framer-motion";
import VocabItemContent from "./VocabItemContent";
import theme from "../../../../@chakra-ui/gatsby-plugin/theme";
import {ChevronDownIcon, ChevronUpIcon} from "@chakra-ui/icons";

const MotionBox = motion(Box)
const MotionFlex = motion(Flex)
const VocabItem = ({tagId,listValue,propMean,diffToRef,numItem}) =>{

    const [isHover, setIsHover] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const name = getNameFromTagId(tagId)

    const propStr = Math.round(propMean/10)/10
    const evolStr = `${diffToRef>=0?"+":""}${(Math.round(diffToRef/10)/10).toString()}`

    const listValueSorted = useMemo(()=>{
        return listValue.sort((a,b) => {return b[1] - a[1]})
    }, [listValue])
    const msgNote = useMemo(()=>{
        const data = listValue[0]
        const propValue = data[1]
        const evolValue = data[2]

        return  `Dans les 7 derniers jours, les tweets et retweets concernant ${getNameFromTagId(data[0])} abordent à ${Math.round(propValue/10)/10}% la thématique ${name},
            en ${evolValue>=0?"hausse":"baisse"} de ${(evolValue/100)} point par rapport aux 2 semaines précédentes`
    },[listValue])
    const textToolTip = useMemo(()=>{
        return `la thématique ${name} a concerné ${propStr}% des tweets politiques ces 7 derniers jours en ${diffToRef>=0?"hausse":"baisse"} de ${(evolStr)} point${Math.abs(diffToRef)>=1?"s":""} par rapport aux 2 semaines précédentes`
    },[name,propMean,diffToRef])
    return <MotionBox animate={isOpen?"open":"close"}
        onMouseEnter={() => !isOpen && setIsHover(true)} onMouseLeave={() => !isOpen && setIsHover(false)}
        pb={3}
    ><MotionFlex
        alignItems={"center"} pt={3}  flexWrap={"wrap"}>
        <Box width={["100%","20%"]} mt={3} textAlign={"center"}>
        <Text as={"span"} fontSize={"20px"} fontFamily={"heading"}    fontWeight={"bold"} p={1} >{name}</Text>
            <Tooltip label={textToolTip} aria-label='A tooltip'>
        <Stat borderColor={"text.50"} width={"100%"} textAlign={"center"}>
            <StatNumber>{propStr} %</StatNumber>
        <StatHelpText color={"text.300"} fontWeight={"bold"} pt={0} mb={0}><StatArrow type={diffToRef>=0?'increase':'decrease'}  />{evolStr}</StatHelpText>
        </Stat>
            </Tooltip>
        </Box>
        <Box width={["100%","80%"]}>
                <Flex position={"relative"} minHeight={150} alignItems={"flex-end"} overflowX={"auto"}>
                    <Box position={"absolute"} height={"0px"} top={"30%"} bg={"text.50"} width={"100%"}/>
                {listValueSorted.map((data)=><VocabPersonItem key={data[0]} tagId={data[0]} propValue={data[1]} evolValue={data[2]}/>)}
                </Flex>
                {(numItem==0) && <Box fontStyle={"italic"} color={"text.200"} lineHeight={[1.3,1.4]} p={1} fontSize={["15px","16px"]}>Lecture : {msgNote}</Box>}


        </Box>

    </MotionFlex>
         {!isOpen && <MotionFlex
             animate={isHover?"hover":"no"}
             variants={{
                 "hover":{borderBottomColor:theme.colors.bg["900"]},
                 "no":{borderBottomColor:theme.colors.bg["700"]}
             }}
             pl={"15px"} pr={"15px"}  justifyContent={"center"}  borderBottomStyle={"solid"} borderBottomWidth={"1px"}>
                  <MotionFlex flexDirection={"column"} justifyContent={"center"} alignItems={"center"} onClick={() => setIsOpen(true)}  cursor={"pointer"} mb={"-8px"}>
                   <MotionBox variants={{
                 "hover":{color:theme.colors.text["100"]},
                 "no":{color:theme.colors.text["50"]}
             }}  fontSize={"12px"} textTransform={"uppercase"}>Plus de détails</MotionBox><ChevronDownIcon bg={"bg.500"} color={"text.100"} /></MotionFlex>
               </MotionFlex>
     }
        {isOpen && <VocabItemContent tagId={tagId} closeContent={()=> setIsOpen(false)}/>}
    </MotionBox>

}



export default VocabItem