import {Box} from "@chakra-ui/react";
import React from "react";
import TopArea from "./TopArea";
import {HeaderSectionBigMain} from "../HeaderSection";


const SectionTopPolitics = ({})=>{

    return <Box>
        <HeaderSectionBigMain  text={"Actualité Générale"} idName={"actualite"}/>
        <TopArea   title={"Top hashtags"} typeItem={"vocab"}/>
        <TopArea  title={"Top Tweets"} typeItem={"tweet"}/>
    </Box>
}

export default  SectionTopPolitics