import React, {useEffect, useMemo, useState} from "react";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th, useMediaQuery, Box, Tooltip,
} from "@chakra-ui/react";
import SumPersonItem from "./SumPersonItem";
import {InfoIcon, InfoOutlineIcon, PhoneIcon} from "@chakra-ui/icons";
import {AnimatePresence, motion, LayoutGroup} from "framer-motion";


const MotionTbody = motion(Tbody)
const SummaryTableSimple = ({listPersonJsonShown,jsonDataFromId})=> {

    const [isSmallScreen, setIsSmallScreen] = useState(false);
      const [mediaQuery] = useMediaQuery("(max-width: 800px)");

      useEffect(() => {
        if(mediaQuery !== isSmallScreen){
          setIsSmallScreen(mediaQuery);
        }
      }, [mediaQuery])

    const listPersonSorted = useMemo(()=>{

        return listPersonJsonShown.sort(function (a, b) {
            const nb_a = jsonDataFromId.hasOwnProperty(a.id)?jsonDataFromId[a.id]["n"]:0
            const nb_b = jsonDataFromId.hasOwnProperty(b.id)?jsonDataFromId[b.id]["n"]:0
                return nb_b - nb_a;
            })
    },[listPersonJsonShown,jsonDataFromId])

    return <Table variant="simple" size='sm'>
        <Thead>
            <TrTable nameTag={"Personnalité"} isSmallScreen={isSmallScreen}/>
        </Thead>

        <MotionTbody layout>
            <LayoutGroup >

            {listPersonSorted.map((t, i) => {
                return <SumPersonItem isSmallScreen={isSmallScreen} key={t.id} numInList={i} tagId={t.id} name={t.n}
                                    url={t.url} data={jsonDataFromId.hasOwnProperty(t.id)?jsonDataFromId[t.id]:null}
                />
            })}
            </LayoutGroup>
        </MotionTbody>
    </Table>
}

const TrTable = ({nameTag,isSmallScreen})=>{
    if(isSmallScreen){
        return <Tr >

        </Tr>
    }else{
        return <Tr >
          <Th textAlign={"center"} color={"text.100"}>{nameTag}</Th>
          <Th textAlign={"center"} color={"text.100"}>Activité <Tooltip label='Nombre de tweets et retweets qui concernent cette personnalité' aria-label='A tooltip'>
                      <InfoIcon ml={1} mb={'4px'} color={"text.50"}/>
                    </Tooltip></Th>
          <Th textAlign={"center"} color={"text.100"}>Posts <Tooltip label='Nombre de tweets envoyés depuis le compte de cette personnalité' aria-label='A tooltip'>
                      <InfoIcon ml={1} mb={'4px'} color={"text.50"}/>
                    </Tooltip></Th>
          <Th textAlign={"center"} color={"text.100"}>Vocabulaire <Tooltip label="Vocabulaire dominant de l'ensemble des tweets concercant cette personnalité" aria-label='A tooltip'>
                      <InfoIcon ml={1} mb={'4px'} color={"text.50"}/>
                    </Tooltip></Th>
        </Tr>
    }
}

export default SummaryTableSimple