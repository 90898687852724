import {Heading,Box} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {addMinutes, LOG} from "../../../tools";
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

import theme, {LIST_COLOR_NAME} from "../../../../@chakra-ui/gatsby-plugin/theme";
import {getNameFromTagId} from "../../../tag/tools";
import {getColorFromNumColor} from "../../../graph/tools";

const JSON_TIME_TO_UNIT = {
    "24":"hour",
    "168":"hour",
    "504":"day"
}
const JSON_TIME_TO_STEPSIZE = {
    "24":3,
    "168":24,
    "504":2
}

const JSON_TIME_TO_STEPWINDOW = {
    "24":1,
    "168":6,
    "504":24
}
const JSON_TIME_TO_DIV_VALUE = {
    "24":1,
    "168":6,
    "504":1,
}
const JSON_TIME_TO_MSG_TOOLTIP = {
    "24":"l'heure",
    "168":"l'heure",
    "504":"la journée",
}
const JSON_TIME_TO_MSG_NOTE = {
    "24":"Dans la dernière heure",
    "168":"Dans la dernière heure d'hier",
    "504":"Hier",
}


const GraphEvol = ({listPersonJsonShown,evolData,timeTopValue})=> {
    //The pattern yyyy-MM-dd isn't an officially supported format for Date
    const startDate = new Date(evolData.hasOwnProperty("sd")?evolData.sd.replace(/-/g, "/"):"")
        const [listLabel,setListLabel] = useState([])
    const [datasetGraph,setDatasetGraph] = useState([])
    const [msgNote, setMsgNote] = useState("")
    const stepWindow = 60*JSON_TIME_TO_STEPWINDOW[timeTopValue]
        useEffect(()=>{

            let list_label = []
            const firstPerson = listPersonJsonShown[0]
            if (evolData.hasOwnProperty("d")){
                 const jsonIdToData = evolData["d"]
             jsonIdToData[firstPerson["id"]]["d"].forEach((value,i)=>{
                list_label.push(
                    addMinutes(startDate,i*stepWindow) )
            })
            //JSON_TIME_TO_MSG_NOTE[timeTopValue]
            setMsgNote(`${JSON_TIME_TO_MSG_NOTE[timeTopValue]}, ${Math.round(jsonIdToData[firstPerson["id"]]["d"][jsonIdToData[firstPerson["id"]]["d"].length - 1]/JSON_TIME_TO_DIV_VALUE[timeTopValue])} tweets
                ont concerné ${getNameFromTagId(firstPerson.id)}`)

            setListLabel(list_label)

            setDatasetGraph(listPersonJsonShown.map((tagInfoGraph,numTag)=>{
            return {
             type:'line',
            label: getNameFromTagId(tagInfoGraph.id),
            data: jsonIdToData[tagInfoGraph["id"]]["d"].map(function(item) { return Math.round(item/JSON_TIME_TO_DIV_VALUE[timeTopValue]) }),
            fill: false,
                borderWidth:2,
                pointRadius:0,
               // pointBorderWidth:0,
            borderColor: getColorFromNumColor(numTag)[500],
                    backgroundColor:getColorFromNumColor(numTag)[300],
            tension: 0.3,
          }
            }))
        }

        },[listPersonJsonShown,evolData])

    const data = {
        labels:listLabel,
      datasets: datasetGraph
    };
    const options = {
   responsive: true,
    maintainAspectRatio: false,
     /*   animation: {
        duration: 1000
    },*/
    plugins:{
        legend:{
            position:'bottom'
        },
        tooltip:{
            enabled:true,
            intersect:false,
            mode:'nearest',
            interaction: {
            mode: 'index',
                axis:'x'
        },
            callbacks: {
                title: function(tooltipItem, data) {
                    return  `Nombre de tweet à ${JSON_TIME_TO_MSG_TOOLTIP[timeTopValue]} \n ${tooltipItem[0].label}`
                },
            }

        }
    },
  scales: {
      x:{
        grid: {display:false,drawBorder: true,},
          type: 'time',
        distribution: 'linear',
          min:startDate,
          time: {
          unit:JSON_TIME_TO_UNIT[timeTopValue],stepSize:1,//JSON_TIME_TO_STEPSIZE[timeTopValue],
          displayFormats: { day:'dd MMM',minute: 'h:MM',hour: "HH:mm"},

            },
          ticks:{
      autoSkip: false,
      maxRotation: 0,
      major: {
        enabled: true
      },
      // color: function(context) {
      //   return context.tick && context.tick.major ? '#FF0000' : 'rgba(0,0,0,0.1)';
      // },
      font: function(context) {
        if (context.tick && context.tick.major) {
            return {
                weight: 'bold',
            }
        }}
      }
      },
    y: {
      display: true,
        ticks: {  beginAtZero: false, display: false     },
        grid: {  display:false,   drawBorder: false,  },
      title: {    display: false}
    },
  },
        layout:{
        padding:{  left: 0, right: 0, top: 0, bottom: 0, }
        }
};

    return <Box>
        <Box height={400}>
           <Line data={data} options={options}/>
        </Box>
        <Box fontStyle={"italic"} color={"text.200"}>Lecture : {msgNote}</Box>
    </Box>
}


export default GraphEvol