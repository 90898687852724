import {Box, Flex, Heading,Text} from "@chakra-ui/react";
import React, {useContext, useEffect, useMemo, useState} from "react";


export const HeaderContent = ({text})=>{


    return <Text mt={2} mb={1}

        fontFamily={"body"} fontWeight={"bold"} fontSize={"14px"} ml={2} color={"text.500"} opacity={0.5}>{text}</Text>
}
/*       position={'relative'} _before={{content: '""',bg:'bg.900',zIndex:-1,
  display: 'inline-block', width: '10px',height:'100%',ml:'-5px',position:'absolute'}}*/