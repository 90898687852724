import React, {useMemo} from "react"

import {Radar} from "react-chartjs-2";
import theme, {LIST_COLOR_NAME} from "../../@chakra-ui/gatsby-plugin/theme";
import {hexToRGB, LOG} from "../tools";
import {Box, Flex, useMediaQuery} from "@chakra-ui/react";
import {getNameFromTagId} from "../tag/tools";

const MAX_VALUE_SCALE = 3
function scaleValue(val, max, min) { return 1 + (val - min) * (MAX_VALUE_SCALE - 1) / (max - min); }
const RadarVocabMulti = ({datasetsRaw,listTagIdToKeep = [25,24,27]})=>{

    const [isLowerThan780] = useMediaQuery("(max-width: 780px)")

    const labelsValue = useMemo(()=>{
        return listTagIdToKeep.map((tag_id)=>getNameFromTagId(tag_id))
    },[listTagIdToKeep])
    const normalValueRadar = 1
    /*const dataset_normal = {
      label: "MOYENNE POLITIQUE",
      backgroundColor: hexToRGB(theme.colors.gray["500"],0.5),
      data: Array(labelsValue.length).fill(normalValueRadar)
    }*/
    const name_value = "d"
const datasets_clean = useMemo(()=>{
    let datatsets_tmp = []
    let default_dataset =  {
        label : "unkonwn",
        tension:0.2,
        }
    datasetsRaw.forEach((dataset_raw,numTag)=>{
        const color =  theme.colors[LIST_COLOR_NAME[numTag%LIST_COLOR_NAME.length]][500]
        datatsets_tmp.push({...default_dataset,...dataset_raw,
            backgroundColor:hexToRGB(color,0.3),
            borderColor:color,
      pointBackgroundColor: color,
        data: listTagIdToKeep.map((tag_id) => dataset_raw["jsonTagIdToData"].hasOwnProperty(tag_id)?dataset_raw["jsonTagIdToData"][tag_id][name_value]:0 )
        })
    })

    let list_value_greater1 = [1] // start with one value usefull if no value greater than one
    datatsets_tmp.forEach((dataset)=> dataset.data.forEach(v => {
            if (v>1) {list_value_greater1.push(v)}
    }) )

    const maxValue = Math.max.apply(null, list_value_greater1)
    const minValue = Math.max.apply(null, list_value_greater1)
    if(maxValue>MAX_VALUE_SCALE){
        datatsets_tmp.forEach((dataset)=> {
        let data_new = []
                dataset.data.forEach(v => {
                    if(v<=1){
                        data_new.push(v)
                    }else{
                        data_new.push(scaleValue(v,maxValue,1))
                    }
                })
        dataset["data"] = data_new
        })
    }
    //datatsets_tmp.push(dataset_normal)
    return datatsets_tmp
}, [datasetsRaw,listTagIdToKeep])

    const data = {
  labels:labelsValue ,
  datasets: datasets_clean
}

const options = {
    responsive: true,
        maintainAspectRatio:false,
    plugins: {
        tooltip: {
            enabled: true,
            mode: 'nearest',
            displayColors: false,
            intersect: false,
             filter: function (tooltipItem) {
                 if( tooltipItem!= null){
                     return tooltipItem.datasetIndex < datasetsRaw.length
                 }else {
                     return true
                 }
    },
            callbacks: {
                title: function (tooltipItem, data) {
                    if(tooltipItem!= null && tooltipItem.length>0){
                        return tooltipItem[0].label
                    }
                    return ""

                },
                labelTextColor: function () {
                    return theme.colors.secondary["300"]
                },
                label: function (tooltipItem, data) {

                    const name = tooltipItem.dataset.label
                    const value = tooltipItem.raw
                    const labelVocab = tooltipItem.label
                        // LOG(tooltipItem,"labelVocab",labelVocab)
                    const tag_id = listTagIdToKeep[tooltipItem.dataIndex]
                    const diff_value = tooltipItem.dataset.jsonTagIdToData[tag_id]["d"]
                    let diff_value_text
                    if(diff_value>=1){
                        diff_value_text = diff_value
                    }else{
                        diff_value_text = `${Math.round((1-diff_value)*100)}%`
                    }
                    return   [`L'activité concernant ${name}`,`aborde ${diff_value_text} fois ${diff_value>=1?"plus":"moins"}`, `cette thématique que la normale`]
                },
            },
        },
        legend: {position: isLowerThan780?'bottom':'right'},
    },

  title: {display: false,text: 'Comparaison'},
 scales: {
        r: {
            angleLines:{color:theme.colors.secondary["300"]},
            pointLabels:{display:true,
                backdropColor:theme.colors.secondary["100"],
                backdropPadding:2,
            color:theme.colors.text["500"],
            font:{family:theme.fonts.heading,size:15,weight:'700'}
            },
            suggestedMin: 0,
            ticks: {display:false}
        },
    },
     layout:{padding:{left: 10,right: 10,top: 10,bottom: 10,}},
  scale: {
    reverse: false,
    gridLines: {
      color: ['black','red','orange','yellow','green','blue','indigo','violet']
    },
    ticks: {beginAtZero: true}
  }
}
    return  <Flex justifyContent={"center"}>
          <Box sx={{mt:'-15px',pl:0,pr:1, height:500,width:'100%',maxWidth:950}}><Radar data={data} options={options}/> </Box>
    </Flex>
  }

export default RadarVocabMulti