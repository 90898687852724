import * as React from "react"
import {
    Box,
    Container, Link,
} from "@chakra-ui/react"

import {useContext, useEffect} from "react";
import {getDataPageLoad} from "../api/getData";
import DataLoadContext from "../../context/DataLoadContext";
import HeaderMainPage from "./HeaderMainPage";
import SectionTopPolitics from "./top/SectionTopPolitics";
import SectionPersonsMain from "../persons/SectionPersonsMain";

import SectionCoupDoeil from "./coupdoeil/SectionCoupDoeil";

import SectionTopics from "./topics/SectionTopics";
import SectionMore from "./more/SectionMore";
import AnalyseMain from "../analyses/global/AnalyseMain";



export const ContentIndex = ({  }) => {


    const {updateLastUpdate} = useContext(DataLoadContext);
    useEffect(() => {
    getDataPageLoad("index").then((d) =>{
        updateLastUpdate(d)
    });
  }, []);


    return <>
        <Box sx={{bg:'bg',fontFamily:'body'}}>
            <Container maxW="container.xl" sx={{
                margin: `10px auto`,
                pl:['2px','10px','10px'],
                pr:['2px','10px','10px'],
            }}>
            <HeaderMainPage/>
                 <ContentIntro/>
                <SectionCoupDoeil/>
            <SectionTopPolitics/>
                <SectionPersonsMain/>
                <SectionTopics/>
                <AnalyseMain/>

                <SectionMore/>
            </Container>

    </Box>
    </>
}
const ContentIntro = ({})=>{
     return <Box sx={{display:'flex',justifyContent:'flex-start',mb:'12px',p:'16px'}}>
        <Box sx={{width:'100%',maxWidth:'100%',pt:[2,3,4,5]}}>

            <Box sx={{fontSize:23,lineHeight:1.1,color:'text.200'}}> La voix des élections est <strong>le journal politique pour suivre en temps réel
                le message des candidats à l'élection présidentielle 2022</strong>.
            </Box>

        </Box>
    </Box>
}
//