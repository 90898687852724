import {Box, Flex, Heading} from "@chakra-ui/react";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {motion} from "framer-motion";
import DataLoadContext from "../../../../context/DataLoadContext";
import {getDataTopFullVocabulary} from "../../../api/getData";
import {LOG} from "../../../tools";
import TicVocabContent from "./TicVocabContent";

const MotionBox = motion(Box)
const MotionFlex = motion(Flex)
const TicVocab = ({rank,timeTopValue,name})=>{

    const {lastUpdate} = useContext(DataLoadContext);

    const [vocabData, setVocabData] = useState(null)

    const lastUpdateTop = lastUpdate.hasOwnProperty("Top")?lastUpdate["Top"]:null
    useEffect(() => {
        if(lastUpdateTop!=null){
            getDataTopFullVocabulary(`${timeTopValue}_${rank}` ,lastUpdateTop).then((d) =>{
            setVocabData(d)
    });
        }

  }, [lastUpdateTop]);


    return <MotionBox>
        {vocabData!=null &&<TicVocabContent  tweetTopicData={vocabData} name={name} timeTopValue={timeTopValue}/>}
    </MotionBox>
}

export default TicVocab