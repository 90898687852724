import * as React from "react"
import {Box, Flex, Spinner} from "@chakra-ui/react";
import {useInView} from "react-intersection-observer";
import {HeaderSectionBigMain} from "../HeaderSection";
import {TwitterFollowButton, TwitterShareButton, TwitterTimelineEmbed} from "react-twitter-embed";
import {useContext, useEffect, useState} from "react";
import DataLoadContext from "../../../context/DataLoadContext";
import {getDataGlobalInfo} from "../../api/getData";
import {LOG} from "../../tools";


const SectionMoreContent = ({})=>{

    const [dataNb,setDataNb] = useState(null)
    const {lastUpdate} = useContext(DataLoadContext);
     const nameFAnalyzer = "GlobalInfo"
    const lastUpdateGlobalInfo = lastUpdate.hasOwnProperty(nameFAnalyzer)?lastUpdate[nameFAnalyzer]:null

    useEffect(() => {
        if(lastUpdateGlobalInfo!=null){
            getDataGlobalInfo(lastUpdateGlobalInfo).then((d) => {
                LOG("getDataGlobalInfo", d)
                setDataNb(d["nb"])
            });
        }
  }, [lastUpdateGlobalInfo]);
     const text = (dataNb!=null)?dataNb.toLocaleString():""

    return <Flex flexWrap={"wrap"} mt={2}>
    <Box width={["100%","100%","60%","70%"]} p={2} >


        <Flex  flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>

            <Box sx={{color:'text.500'}} textAlign={"center"} fontSize={"22px"}> <strong>La voix des élections</strong> est le journal politique pour suivre en temps réel
                le message des candidats à l'élection présidentielle 2022.
            {text!="" &&
            <Box ><strong>{text} Tweets</strong> ont été analysés depuis mai 2021 </Box>}
        </Box>
            <Box  m={3} width={"15px"} height={"15px"} bg={"primary.100"}></Box>
            <Box width={"100%"} textAlign={"center"} p={1} fontWeight={"bold"} fontSize={"22px"}>
                <Box as={"span"}  fontStyle={"italic"}>Si vous trouvez ce site utile n'hésitez pas à le partager</Box> 🤗 </Box>
              <Box p={2}> <TwitterShareButton lang={"fr"}
    url={'https://lavoixdeselections.fr'}
            options={{ text: "Enfin un moyen clair de suivre ce que disent les candidats à l'élection présidentielle ! #LaVoixDesElections", via: 'voixElections' }}
              /></Box>
            <Box width={"100%"} mt={2} textAlign={"center"} p={1} fontWeight={"bold"} fontSize={"22px"} fontStyle={"italic"}> Et nous suivre  </Box>
            <Box p={2} > <TwitterFollowButton  lang={"fr"}
        screenName={'voixElections'}
      /></Box>
        </Flex>


    </Box>
<Box width={["100%","100%","40%","30%"]} maxWidth={"400px"} p={2}>
         <Box boxShadow={"cardItem"}
            borderColor={"bg.900"} borderWidth={"1px"} borderStyle={"solid"} borderRadius={"5px"}
         ><TwitterTimelineEmbed
              lang={"fr"}
  sourceType="profile"
  screenName="voixElections"
  options={{height: 400}}
/>
             </Box>
    </Box>

    </Flex>
}

const SectionMore = () => {
  const { ref, inView, entry } = useInView({
      triggerOnce: true ,
    threshold: 0,
  });
  return (
    <div ref={ref}>
        <HeaderSectionBigMain  text={"Nous suivre"} idName={"more"}/>
        {inView && <SectionMoreContent/>}
        {(!inView) && <Flex justifyContent={"center"} height={800}>
            <Spinner color="text.100" />
        </Flex>}
    </div>
  );
};

export default SectionMore