import {Box, Flex, } from "@chakra-ui/react";
import React, {useMemo} from "react";
import { motion} from "framer-motion";
import TicVocab from "./TicVocab/TicVocab";
import {TicTweetContent} from "./TicTweet/TicTweetContent";

const MotionBox = motion(Box)
const MotionFlex = motion(Flex)
const TopItemContent = ({rank,jsonItem,isOpen,typeItem,timeTopValue})=>{

    const content = useMemo( () => {
        switch (typeItem) {
            case "vocab":
                return <MotionBox variants={{
                            "open": {},
                            "close": {},
                        } }>{isOpen && <TicVocab timeTopValue={timeTopValue} name={jsonItem["n"]} rank={rank} timeTopValue={timeTopValue}/> }</MotionBox>
            case "tweet":
                return <MotionBox zIndex={1}>
                    <TicTweetContent isOpen={isOpen} tweetFrontData={jsonItem} listHighlight={[]}/>
                </MotionBox>
        }
    },[typeItem,jsonItem,isOpen])

    return content
}


export default TopItemContent