
import * as React from "react"
import {useMemo} from "react";
import {LOG} from "../../tools";
import {Box} from "@chakra-ui/react";


export const TweetToken = ({token,listHighlight})=>{

    const isHighlighted = useMemo(()=> {
        let result = false
        listHighlight.some((t)=>{
            const isEqual = t.TC == token.TC
            if (isEqual) {
                result= true
                return true
            }
            return false
        })
        return result
    },[listHighlight])

    return <Box as={"span"} sx={{fontWeight:isHighlighted?"bold":"body"}}>{token.T}{token.S}</Box>
}