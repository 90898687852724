import {
    Avatar,
    Box,
    Flex,
    Heading,
    Spinner, Stat,
    StatArrow,
    StatHelpText,
    StatLabel,
    StatNumber,
    Td,
    Tr
} from "@chakra-ui/react";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {motion} from "framer-motion";
import theme from "../../../../@chakra-ui/gatsby-plugin/theme";
import { Link } from '@chakra-ui/react'
import {getTypeFromTagId,getUrlImgFromTagId,getFullUrlFromTagTypeAndUrl} from "../../../tag/tools";
import {LOG} from "../../../tools";
import {ChevronRightIcon, ExternalLinkIcon} from "@chakra-ui/icons";

const MotionTr = motion(Tr)
const MotionTd = motion(Td)
const MotionBox = motion(Box)
const MotionFlex = motion(Flex)
const MotionAvatar = motion(Avatar)

const SumPersonItem = ({name,tagId,url,numInList,isSmallScreen,data})=> {

    const urlImg = getUrlImgFromTagId(tagId, 100)
    const [isHover, setIsHover] = useState(false)

    const dataIsLoading = data==null
    const {v,n,t,pn,pt} = dataIsLoading?{}:data
    const dataActivity = {"nb":n, "trend":t}
    const dataPost = {"nb":pn, "trend":pt}

    const [isOpen,setIsOpen] =useState(false)


    const tagType = getTypeFromTagId(tagId)
    const fullUrl = getFullUrlFromTagTypeAndUrl(tagType, url)

    const isOdd = (numInList % 2)
    const rank = numInList + 1
    const colorBG =  isOdd ? theme.colors.bg["700"] : theme.colors.bg["500"]

    if (!isSmallScreen) {
        const contentItem = <>
        <MotionTd position={"relative"}>
            <Box position={"absolute"} top={"50%"} left={"6px"} color={"text.100"} fontSize={"16px"} fontWeight={"bold"}>#{rank}</Box>
        <MotionFlex sx={{justifyContent: 'center', flexDirection: 'column', alignItems: "center"}}>
            <MotionAvatar
            variants={{
                hover: {filter: 'grayscale(40%)', opacity: 1},
                default: {filter: 'grayscale(80%)', opacity: 0.8}
            }} src={urlImg} width={50} height={50}/>
            <Heading p={2} textAlign={"center"} fontSize={["16px", "18px"]}>{name}</Heading>
        </MotionFlex></MotionTd>
        <Td><TagItemValue typeInfo={"activity"} data={dataActivity} dataIsLoading={dataIsLoading}/></Td>
        <Td><TagItemValue typeInfo={"posts"} data={dataPost} dataIsLoading={dataIsLoading}/></Td>
        <Td><TagItemValue typeInfo={"vocab"} data={v} isOdd={isOdd} dataIsLoading={dataIsLoading}/></Td>
        <MotionTd p={1}><MotionBox textAlign={"center"}><Link href={fullUrl} isExternal ><ExternalLinkIcon mx='2px' />
            <MotionBox variants={{
                hover: {opacity: 1},
                default: {opacity: 0.4}
            }}>Détails</MotionBox>
</Link></MotionBox></MotionTd>
        </>
        return <MotionTr position={"relative"} layout
                         onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}
                         animate={(isHover) ? "hover" : "default"}
                         variants={{
                             hover: {backgroundColor: theme.colors.bg["900"]},
                             default: {backgroundColor: colorBG}
                         }}
        >
            {contentItem}
        </MotionTr>
    } else {
        return <Tr position={"relative"}><Td p={0}>
            <Box position={"absolute"} top={"5px"} right={"6px"} color={"text.100"} fontSize={"16px"} fontWeight={"bold"}>#{rank}</Box>
            <Flex bg={colorBG} width={"100%"}  p={[0,2]} pt={2} pb={2} flexWrap={"wrap"} justifyContent={"center"}>
            <Flex width={"100%"} sx={{justifyContent: 'center',flexDirection:'columns', flexWrap: 'wrap', alignItems: "center"}}>
               <MotionAvatar
            sx={{filter: 'grayscale(80%)', opacity: 1}}
            src={urlImg} width={70} height={70}/>
            <Heading width={"100%"} p={2} textAlign={"center"} fontSize={["19px"]}>{name}</Heading>
        </Flex>
            <Box width={"50%"}><TagItemValue typeInfo={"activity"} data={dataActivity} dataIsLoading={dataIsLoading}/></Box>
            <Box width={"50%"}><TagItemValue typeInfo={"posts"} data={dataPost} dataIsLoading={dataIsLoading}/></Box>
            <Box width={"100%"}><TagItemValue  typeInfo={"vocab"} data={v} isOdd={isOdd} isSmallScreen={isSmallScreen} dataIsLoading={dataIsLoading}/></Box>
        <Box p={1} m={1} width={"100 %"} textAlign={"center"}>
                    <Link borderStyle={"solid"} borderColor={"text.50"} borderWidth={"1px"} p={1} borderRadius={"3px"}
                          color={"text.100"}
                        href={fullUrl} > <ChevronRightIcon mx='0px' mb={"3px"} pb={"1px"} color={"text.100"} /> Accéder à plus de détails</Link> </Box>
        </Flex>
            </Td>
            </Tr>
    }
}

const TagItemValue = ({typeInfo, data, isSmallScreen = false,isOdd=false,dataIsLoading=false})=>{
    const contentDiv = useMemo (() => {
        if (dataIsLoading){
            return <Flex justifyContent={"center"}><Spinner color="text.100" /></Flex>
        }
        switch (typeInfo) {
            case "activity":
                const evolA = data["trend"] - 100
                return <>
                        <Flex sx={{
                        textAlign: 'center',
                        fontSize: 25,
                        fontWeight: 'bold',
                        letterSpacing: 1.8,
                        minHeight: '50px',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                     <Stat>
                    <StatNumber>{data["nb"].toLocaleString()}</StatNumber>
                    <StatLabel color={"text.100"} lineHeight={1} pt={1}>Tweets & Retweets</StatLabel>
                    <StatHelpText color={"text.300"} fontWeight={"bold"} pt={1} mb={0}>
                      <StatArrow type={evolA>=0?'increase':'decrease'}  />
                        {evolA}%
                    </StatHelpText>
                  </Stat>
                    </Flex>
                       </>
            case "posts":
                const evol = data["trend"] - 100
                return <>
                    <Flex sx={{
                        textAlign: 'center',
                        fontSize: 25,
                        fontWeight: 'bold',
                        letterSpacing: 1.8,
                        minHeight: '50px',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                      <Stat>
                    <StatNumber>{data["nb"].toLocaleString()}</StatNumber>
                    <StatLabel color={"text.100"} lineHeight={1} pt={1}>Tweets envoyés</StatLabel>
                    <StatHelpText color={"text.300"} fontWeight={"bold"} pt={1} mb={0}>
                      <StatArrow type={evol>=0?'increase':'decrease'}  />
                        {evol}%
                    </StatHelpText>
                  </Stat>
                    </Flex>
                </>
            case "vocab":
                const listToken = data.map(token => token).slice(0,isSmallScreen?6:10)
                return <>
                    {isSmallScreen && <Box sx={{textAlign: 'center',textTransform:'uppercase',lineHeight:1.2,color:'text.100',
                        fontSize: 16, fontWeight: 'body', letterSpacing: 1.5, pb:'0px'}}>Vocabulaire</Box>
                    }
                    <Flex sx={{
                        textAlign: 'center',
                        fontSize: 25,
                        fontWeight: 'bold',
                        letterSpacing: 1.8,
                        minHeight: ['50px','50px','50px'],
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}>
                        <Flex sx={{justifyContent:"center",fontWeight:'bold',flexWrap:'wrap',p:'4px'}}>{listToken.map((token,i)=>
                            <Box key={i} sx={{bg:'transparent',ml:'3px',mr:'3px',mt:'4px',p:['1px','2px','4px'], borderRadius:'4px',/* isOdd?'bg.300':'white*/
                                borderColor:'text.50',borderStyle:'solid',borderWidth:'0px',letterSpacing:0.9,
                                fontSize:['16px','18px'],color:'text.200',whiteSpace:'normal',fontFamily:'body',fontWeight:'bold'}}>
                    {token} </Box>)} </Flex>
                    </Flex>

                    </>
            default:
                return <div></div>
        }
    },[typeInfo,data,dataIsLoading])

    return <Box sx={{minHeight:'50px',mt:[0,'6px']
    }}> <Flex sx={{ml:'4px',mr:'4px',mt:0,borderRadius:'5px',minHeight:'80px', height:'100%',
                justifyContent:'center',flexDirection:'column'}}>
                       {contentDiv}
        </Flex>
            </Box>
}

export default SumPersonItem