import {Avatar, Box, Flex, Heading} from "@chakra-ui/react";
import React, {useMemo} from "react";
import {getNameFromTagId, getUrlImgFromTagId} from "../../tag/tools";
import {motion} from "framer-motion";


const MotionAvatar = motion(Avatar)
const CDI_person = ({data}) => {

    return <Flex height={"100%"} justifyContent={"center"} flexDirection={"column"} m={1}>
        {data.map((p, i) =>
            <PersonItem key={i} rank={i+1} tagId={p[0].toString()} nb={p[1]}/>)}
    </Flex>

}

const PersonItem = ({tagId,nb,rank})=>  {
    const urlImg =  getUrlImgFromTagId(tagId,50)
    const nameTag =getNameFromTagId(tagId)
    return <Flex justifyContent={"flex-start"} alignItems={"center"} m={1} p={1}>
        <Box fontSize={"22px"} fontWeight={"bold"} color={"text.200"} p={1} pr={2}>#{rank}.</Box>
        <MotionAvatar src={urlImg} variants={{hover:{filter:'grayscale(50%)'},default:{filter:'grayscale(100%)'} }}
                                            height={"40px"}    width={"40px"} p={0}/>
        <Box p={1} fontWeight={"bold"} fontSize={"20px"} maxWidth={"200px"} lineHeight={1}>
            {nameTag}
        <Box p={0} fontWeight={"body"} fontSize={"15px"} >{nb} Tweets<Box as={"span"} color={"text.100"} fontSize={"10px"}> (en 4h)</Box>
        </Box>
        </Box>
    </Flex>
}


export default CDI_person