import {Box} from "@chakra-ui/react";
import React from "react";
import {HeaderSectionBigMain} from "../HeaderSection";
import SectionSummaryPersons from "../persons/summary/SectionSummaryPersons";
import SectionVocabsPersons from "../persons/vocabs/SectionVocabsPersons";
import SectionTopicsSummary from "./SectionTopicsSummary";

const SectionTopics = ({})=>{

    return <Box>
        <HeaderSectionBigMain  text={"Grandes Thématiques"} idName={"thematiques"}/>

        <SectionTopicsSummary />
        <SectionVocabsPersons/>

    </Box>
}

export default  SectionTopics