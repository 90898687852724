import {Box, Flex, Spinner} from "@chakra-ui/react";
import React, {useContext, useEffect, useState} from "react";
import CoupDoeilItem from "./CoupDoeilItem";
import DataLoadContext from "../../../context/DataLoadContext";
import {getDataGlobalInfo} from "../../api/getData";
import {LOG} from "../../tools";
import {HeaderSectionBigMain} from "../HeaderSection";
import {useInView} from "react-intersection-observer";


const SectionCoupDoeilContent = ({})=>{
    const {lastUpdate} = useContext(DataLoadContext);
     const nameFAnalyzer = "GlobalInfo"
    const lastUpdateGlobalInfo = lastUpdate.hasOwnProperty(nameFAnalyzer)?lastUpdate[nameFAnalyzer]:null
    const [listWord,setListWords] = useState(null)
    const [topTweet,setTopTweet] = useState(null)
    const [topPerson,setTopPerson] = useState(null)
    const [hierId,setHierId] = useState(null)
    useEffect(() => {
        if(lastUpdateGlobalInfo!=null){
            getDataGlobalInfo(lastUpdateGlobalInfo).then((d) => {
                LOG("get_data", d)
                setListWords(d["w"])
                setTopTweet(d["t"])
                setTopPerson(d["p"])
                setHierId(d["h"])
            });
        }
  }, [lastUpdateGlobalInfo]);


    return <Box>
        <Flex flexWrap={"wrap"} justifyContent={"center"}>
            <CoupDoeilItem type={"w"} data={listWord}/>
            <CoupDoeilItem type={"t"} data={topTweet}/>
            <CoupDoeilItem type={"p"} data={topPerson}/>
            <CoupDoeilItem type={"h"} data={{hierId}}/>
        </Flex>
    </Box>
}

const SectionCoupDoeil = () => {
  const { ref, inView, entry } = useInView({
      triggerOnce: true ,
    threshold: 0,
  });
  return (
    <Box ref={ref}>
        <HeaderSectionBigMain   text={"En un coup d'oeil"} idName={"intro"}/>
        {inView && <SectionCoupDoeilContent/>}
        {(!inView) && <Flex justifyContent={"center"} height={800}>
            <Spinner color="text.100" />
        </Flex>}
    </Box>
  );
};


export default  SectionCoupDoeil