

import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box} from "@chakra-ui/react";
import DataLoadContext from "../../../context/DataLoadContext";
import { getDataTagTopics} from "../../api/getData";
import {addMinutes, LOG} from "../../tools";
import LineEvolTopics from "./LineEvolTopics";

const GraphEvolTopics = ({})=>{

    const {lastUpdate} = useContext(DataLoadContext);

    const [jsonLineTags,setJsonLineTags] = useState({})
    const [listLabel,setListLabel] = useState([])

    const lastUpdateTagTopics = lastUpdate.hasOwnProperty("TagTopics")?lastUpdate["TagTopics"]:null
    useEffect(() => {
        if(lastUpdateTagTopics!=null){
            getDataTagTopics("2232" ,lastUpdateTagTopics).then((d) =>{
            LOG("getDataTagTopics",d)

                setJsonLineTags(d["d"])
                const startDate = new Date(d["sd"])
                const stepWindow = 60*24
            const listTagId = Object.keys(d["d"])
              const tag_id_test =  listTagId[0]
              const nbPoints = d["d"][tag_id_test].length
                let list_label = []
            for (const i of Array(nbPoints).keys()) {
              list_label.push(addMinutes(startDate,i*stepWindow)
                 )
             }
            setListLabel(list_label)
         });

    }
  }, [lastUpdateTagTopics]);


    return <Box id={"graph_evol_topic"}>
            <LineEvolTopics
            listLabel={listLabel}
            jsonLineTags={jsonLineTags}
            />
    </Box>
}



export default GraphEvolTopics