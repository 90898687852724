
import React, {useMemo} from "react";
import {addMinutes} from "../../../tools";
import {SColorPrimary, SColorText} from "../../../../gatsby-plugin-theme-ui-old";
import {Line} from "react-chartjs-2";
import {Box} from "@chakra-ui/react";

import theme from "../../../../@chakra-ui/gatsby-plugin/theme";
const optionsDate = { year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit',minute:'2-digit' };

export const AreaGraph = ({listTweetFront,durationTopic,dateStartTopic,timeWeightsTopic})=>{
    const dateStart = new Date(dateStartTopic.replace(/-/g, "/"))
    const dateEnd = addMinutes(dateStart,durationTopic)

    const dateStartStr = useMemo(()=>{
        return dateStart.toLocaleDateString("fr-FR", optionsDate)
    },[dateStart])
    const dateEndStr = useMemo(()=>{
        return dateEnd.toLocaleDateString("fr-FR", optionsDate)
    },[dateEnd])

    const stepValue = durationTopic / timeWeightsTopic.length
    let list_label = []

    timeWeightsTopic.forEach((value,i)=>{
        list_label.push(addMinutes(dateStart,i*stepValue  )
             )
    })

    let datasetTweets = []
        /*[{x:addMinutes(dateStart,0), y:0},
    {x:dateEnd, y:0}]*/
    listTweetFront.forEach((tf,i)=> {
        datasetTweets.push({x:new Date(tf["date"].replace(/-/g, "/")), y:tf["nbr"]})
    })

    let datasetWeights = []

        const lagForGraph = 15
    const nbValue = timeWeightsTopic.length
    timeWeightsTopic.forEach((value,i)=>{
        const minuteFromStart = i*stepValue + lagForGraph
       /* if(minuteFromStart<time_tweet & ((minuteFromStart+stepValue)>time_tweet)){
            datasetWeights.push({})
        }*/
        datasetWeights.push({x:addMinutes(dateStart,minuteFromStart), y:value})
    })

    const colorT = theme.colors.primary
const data = (canvas) => {
    return {
        labels: list_label,
            datasets: [{
            type:'line',
        data: datasetWeights,
        label: "Nb Tweet",
        lineTension:0.6,
        borderWidth:1,
        pointRadius:0,
                order:1,
                barPercentage:0.1,
        backgroundColor:colorT[100],
        borderColor: SColorText[100],
          fill:true,
    },
                {
                    type: 'bar',
        data: datasetTweets,
                    skipNull:true,
        //barThickness:5,
                    //minBarLength: 2,
                    maxBarThickness: 8,
                order:0,
        label: "Retweet",
        borderWidth:1,
        borderRadius: 5,
        yAxisID: 'y1',
        xAxisID: 'x1',
        backgroundColor:`${colorT[700]}80`,
                    hoverBackgroundColor:colorT[700],
        borderColor: "#3e95cd",
          fill:true,
    }

    ]
    }
}
    const options = {
    maintainAspectRatio:false,
        animation:{duration:0},
    plugins:{
       legend: {    display:false, }
    },
  scales: {
      x:{display:false,grid: { display:false, drawBorder: false, },type: 'timeseries',
        distribution: 'linear',
          min:dateStart,
          max:dateEnd,
          time: { bounds:'ticks',unit:'hour', stepSize:12,
          displayFormats: {day:'dd MMM yyyy',minute: 'h:MM',  hour: "dd/MM-HH:mm"  },
            ticks:{ source:'auto', }
            }
        },
      x1:{display:false,grid: { display:false, drawBorder: false, },type: 'timeseries',
          min:dateStart,
          max:dateEnd,
          time: { bounds:'ticks',unit:'hour', stepSize:12,
          displayFormats: {day:'dd MMM yyyy',minute: 'h:MM',  hour: "dd/MM-HH:mm"  },
            ticks:{ source:'auto', }
            }
        },
    y: {display: false,
        ticks: {  beginAtZero: false,  display: false    },
        grid: {  display:false,  drawBorder: false,   },
      title: { display: false,       }
        },
      y1: {display: false,
        ticks: {  beginAtZero: false,  display: false    },
        grid: {  display:false,  drawBorder: false,   },
      title: { display: false,       }
        }

      },
     layout:{
        padding:{
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    }
        }
    };




    return <Box sx={{width:'100%',height:'50px'}}><Line data={data} options={options}
        /></Box>
}