import {Box, Flex, Heading, Link, Text, Tooltip, useMediaQuery} from "@chakra-ui/react";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {motion} from "framer-motion";

import {AreaGraph} from "./TicGraphAreaVocab";
import {SColorBG} from "../../../../gatsby-plugin-theme-ui-old";
import {TweetCardTop} from "../../../tweet/card/TweetCardTop";
import {TagCard} from "../../../tag/TagCard";
import {HeaderContent} from "../topComponents";
import TouchFriendlyTooltip from "../../../tools/TouchFriendlyTooltip";
import {ExternalLinkIcon} from "@chakra-ui/icons";

const MotionBox = motion(Box)
const MotionFlex = motion(Flex)
const maxAreaHeight = 350

const sxArea = {bg:'bg.500',overflow:'auto',borderRadius:'3px',m:2,mt:"1px",p :'4px', border:`solid 1px ${SColorBG[900]}` }

const JSON_TIME_TO_MSG = {"24":" 24 heures", "4" : "4 heures", "168": "1 semaine"}

const TicVocabContent = ({tweetTopicData,timeTopValue,name})=>{
        const {id,n,nb} = tweetTopicData
    const listToken = tweetTopicData.lt
    const trendTopic = tweetTopicData.t
    const listTweetFront = tweetTopicData.ltf
    const dateStartTopic = tweetTopicData.ds
    const durationTopic = tweetTopicData.dur
    const timeWeightsTopic = tweetTopicData.tw
    const listPropTags = tweetTopicData.hasOwnProperty("pt")?tweetTopicData["pt"]: []
    const [isLowerThan680] = useMediaQuery("(max-width: 680px)")
    return <MotionBox>
            <AreaGraph listTweetFront={listTweetFront} timeWeightsTopic={timeWeightsTopic}
                   dateStartTopic={dateStartTopic}
                   durationTopic={durationTopic}/>
       <MotionFlex sx={{width: '100%', justifyContent: 'stretch',flexWrap:'wrap', alignItems: 'stretch',pb:'0',
       borderTopStyle:"solid",borderTopWidth:'1px',borderTopColor:'text.100'
       }}>
           <MotionFlex sx={{width:['100%','50%','65%'],position:'relative',flexWrap:"wrap",alignItems:"flex-start"}}>
               <Flex width={["100%","100%","50%"]} flexWrap={"wrap"} alignItems={"stretch"}>
               <Box width={["100%","100%","100%"]} p={1} pt={0}>
                   <Flex justifyContent={"flex-start"} height={"100%"} alignItems={"center"}
                        borderStyle={"solid"} borderWidth={"0px"} borderColor={"text.50"}
                   >
                       <Text fontSize={"30px"} fontWeight={"bold"}>{nb}</Text>
                       <Box p={1}>
                           <Box height={"20px"} > TWEETS </Box>
                        <Box p={0} color={"text"} textAlign={"right"} fontSize={"12px"} color={"text.300"} lineHeight={'5px'}
                             height={"10px"} >en {JSON_TIME_TO_MSG[timeTopValue]}</Box>
                       </Box>
                   </Flex>
               </Box>
               <Box width={["100%","100%","100%"]}><HeaderContent text={"Vocabulaire associé"}/>
                <Box  sx={sxArea}>
                        <Flex sx={{
                            justifyContent:'flex-start',flexWrap:'wrap'}}>
                    {listToken.map((token,i)=><Box as={"span"} key={i}
                                         sx={{bg:'white',m:'3px',p:'2px',
                                             borderRadius:'2px',
                                             borderStyle:'solid',borderWidth:'1px',borderColor:'text.50',
                                             fontSize:14,color:'text.500',whiteSpace:'normal',fontWeight:'bold'}}>
                        {token.hasOwnProperty("T")?token.T:token.TC} </Box>)}
            </Flex>
           </Box>
                </Box>
            </Flex>
               <Box width={["100%","100%","50%"]}>
                   <Flex alignItems={"center"}><HeaderContent text={"Les personnalités, partis, thématiques"}/>
                    <TouchFriendlyTooltip label={`Les principales personnalités, partis et thématiques concercernés par les tweets contenant ${name}`}/>

                   </Flex>
                   <Box sx={sxArea}
                        minHeight={["30px",maxAreaHeight,maxAreaHeight]}
                        maxHeight={maxAreaHeight}>
                       <AreaTags listPropTags={listPropTags}/>
                   </Box>

               </Box>
           </MotionFlex>

           <MotionBox sx={{width:['100%','50%','35%'],position:'relative'}}>
               <HeaderContent text={"Les tweets importants"}/>
               <AreaTweets listToken={listToken} listTweetFront={listTweetFront}/>
           </MotionBox>
            {/*<AreaInfo name={n} nb={nb} trend={trendTopic} listToken={listToken} isActive={true} listPropTags={listPropTags}/>*/}
            {/*<AreaTweets listTweetFront={listTweetFront} listToken={listToken} isActive={true}/>*/}
        </MotionFlex>
        <Flex justifyContent={"center"}><Link href={`https://twitter.com/search?q=%23${name.slice(1,name.length)}`}
                         borderColor={"text.50"} borderStyle={"solid"} borderWidth={"1px"}
                                              borderRadius={"4px"} p={1}
                                              isExternal={!isLowerThan680} color={"text.200"}> Suivre sur twitter <ExternalLinkIcon mx='2px' /></Link>
        </Flex>
        </MotionBox>
}



const AreaTags = ({listPropTags})=>{

    const listPropTagsFiltered = listPropTags.filter((propTag) => propTag[1]>10)
    return <Box>
        {listPropTagsFiltered.map((propTag) => <TagCard
                key={propTag[0]}
            tagId={propTag[0]}
            tagProp={propTag[1]}
            diffNormal={propTag[2]}
            /> )}
    </Box>
}


export const AreaTweets = ({listTweetFront,listToken})=>{

    const listHighlight = useMemo(()=>{
        let arrayTmp = []
        listToken.map((token)=>{
               const array_token_TC = token.TC.split(" ")
            array_token_TC.map((tokenTC)=>{
                arrayTmp.push({TC:tokenTC, T:tokenTC, W:token.W})
            })
        })
        return arrayTmp

    },[listToken])

    return <Box sx={sxArea} maxHeight={[maxAreaHeight,2*maxAreaHeight,maxAreaHeight]} >
                   {listTweetFront.map((tweetFrontData,i) =>
                    <TweetCardTop key={tweetFrontData.id} tweetFrontData={tweetFrontData}
                listHighlight={listHighlight}
                /> )}
           </Box>
}



export default TicVocabContent