import {HeaderSectionBigMain} from "../mainPage/HeaderSection";
import React from "react";
import {
    Box, Flex, Spinner, Heading,Text,
} from "@chakra-ui/react";
import {useInView} from "react-intersection-observer";
import {motion} from "framer-motion";
import TagMenuSelect from "../tag/components/TagMenuSelect";
import {LIST_ALL_PERSON} from "../tag/tools";
import SectionSummaryPersons from "../mainPage/persons/summary/SectionSummaryPersons";
import SectionEvolPersons from "../mainPage/persons/evol/SectionEvolPersons";
import SectionVocabsPersons from "../mainPage/persons/vocabs/SectionVocabsPersons";

const MotionText = motion(Text)

const PersonsMainContent = ({})=>{
        const listPersonJson = LIST_ALL_PERSON
    return <div>
        <Flex justifyContent={"space-evenly"} mt={4} mb={[1,3]} p={1} alignItems={"center"} flexWrap={"wrap"}>
            <Box  p={2}>
                <MotionText  sx={{fontSize:['16px','20px'],lineHeight:[1.1,1.2],color:'text.200'}} animate={{x:-5}}>Un ensemble important de personnalités politiques est suivi sur Twitter.
                La-Voix-Des-Elections regroupe l'ensemble des informations liées à ces personnalités.</MotionText>
                </Box>
            <Box><TagMenuSelect listTag={listPersonJson} tagType={"P"} /></Box>
        </Flex>
         <SectionEvolPersons/>
        <SectionSummaryPersons/>
    </div>
}

const SectionPersonsMain = () => {
  const { ref, inView, entry } = useInView({
      triggerOnce: true ,
    threshold: 0,
  });
  return (
    <div ref={ref}>
        <HeaderSectionBigMain  text={"Par Personnalité"} idName={"personnalite"}/>
        {inView && <PersonsMainContent/>}
        {(!inView) && <Flex justifyContent={"center"} height={800}>
            <Spinner color="text.100" />
        </Flex>}
    </div>
  );
};


export default SectionPersonsMain