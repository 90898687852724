import {Box, Flex, Heading, Spinner} from "@chakra-ui/react";
import React, {useMemo} from "react";
import CDI_word from "./CDI_word";
import CDI_tweet from "./CDI_tweet";
import CDI_person from "./CDI_person";
import Link from "gatsby-link";
import CDI_hier from "./CDI_hier";


const CoupDoeilItem = ({data,type}) =>{
    const titre = useMemo(()=>{
        switch(type){
            case "p":
                return "Top Personnalités"
            case "w":
                return "Top hashtag"
            case "t":
                return "Top tweet"
            case "h":
                return "Vocabulaire de la veille"
        }
    },[type,data])

    const urlMore = useMemo(()=>{
        switch(type){
            case "p":
                return "/#personnalite"
            case "w":
                return "/#top_vocab"
            case "t":
                return "/#top_tweet"
        }
    },[type,data])
    const content = useMemo(()=>{
        if(data==null){
            return <Flex justifyContent={"center"} alignItems={"center"} width={'150px'} height={"200px"}><Spinner color="text.100" /></Flex>
        }
        switch(type){
            case "p":
                return <CDI_person data={data} />
            case "w":
                return <CDI_word data={data}/>
            case "t":
                return <CDI_tweet data={data}/>
            case "h":
                return <CDI_hier data={data}/>
        }
    },[type,data])

    return <Box maxWidth={["100%","100%","50%",type=="h"?"50%":"33%"]} minWidth={["100%","100%",type=="w"?"370px":"300px"]} mt={[1,4]} mb={4}>
        <Box ml={[2,5]} fontWeight={"bold"} color={"text.200"}>{titre}</Box>
    <Box bg={"white"} boxShadow={"cardItem"} m={[1,4]} mt={[0,0]} mb={[0,0]} borderRadius={"4px"} p={type=="h"?0:1} height={type=="h"?"auto":"calc(100% - 25px)"}>
        {content}
    </Box>
        {type!="h" && <Box textAlign={"right"} pr={4} color={"text.50"}><Link to={urlMore}> En savoir plus</Link></Box>}
    </Box>
}

export default CoupDoeilItem