import React from 'react'
import {Box} from "@chakra-ui/react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    //log the error to an error reporting service
    //errorService.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <Box textAlign={"center"} p={2} mt={3} mb={3} fontStyle={"italic"}  >Problème pour charger ce contenu. Essayez plus tard ;)</Box>;
    }
    return this.props.children;
  }
}
export default ErrorBoundary