import {Box, Flex, Spinner} from "@chakra-ui/react";
import {useInView} from "react-intersection-observer";
import {HeaderSectionBigMain} from "../../mainPage/HeaderSection";
import React from "react";
import AnalysesContent from "./AnalysesContent";


const AnalyseMainContent = ({})=>{
    return <Box>
            <AnalysesContent />
    </Box>
}

const AnalyseMain = () => {
  const { ref, inView, entry } = useInView({
      triggerOnce: true ,
    threshold: 0,
  });
  return (
    <div ref={ref}>
        <HeaderSectionBigMain  text={"Les analyses"} idName={"analyses"}/>
        {inView && <AnalyseMainContent/>}
        {(!inView) && <Flex justifyContent={"center"} height={800}>
            <Spinner color="text.100" />
        </Flex>}
    </div>
  );
};


export default AnalyseMain