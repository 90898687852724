import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box} from "@chakra-ui/react";
import TabTime from "../../tools/TabTime";
import {HeaderSectionBig} from "../../HeaderSection";
import DataLoadContext from "../../../../context/DataLoadContext";
import {getDataPersonsEvol} from "../../../api/getData";
import {LOG} from "../../../tools";
import PersonalizedContentContext from "../../../../context/PersonalizedContent";
import {LIST_ALL_PERSON} from "../../../tag/tools";
import SummaryTableSimple from "../summary/SummaryTableSimple";
import GraphEvol from "./GraphEvol";
import ErrorBoundary from "../../../tools/ErrorBoundary";


const SectionEvolPersons = ({}) =>{

    const [indexTimeValue,setIndexTimeValue] = useState(0)
    const listTimeValue = [{id:"24",n:" 24 heures"}, {id: "168", n: "1 semaine"}, {id: "504", n: "3 semaines"}]
    const timeTopValue = listTimeValue[indexTimeValue]["id"]
    const listPersonJson = LIST_ALL_PERSON
    const {lastUpdate} = useContext(DataLoadContext);

    const [evolData, setEvolData] = useState({})

    const lastUpdatePersons = lastUpdate.hasOwnProperty("Persons")?lastUpdate["Persons"]:null
    useEffect(() => {
        if(lastUpdatePersons!=null){
            getDataPersonsEvol(timeTopValue ,lastUpdatePersons).then((d) =>{
            setEvolData(d)
                /* { d: {1:{d:[]}, 2:... },  sd: "2021xxxxx"} */
            LOG("getDataPersonsEvol",d)
    });
        }

  }, [lastUpdatePersons,timeTopValue]);

    const {jsonTagVisible} = useContext(PersonalizedContentContext);
    const tagType = "P"
    const objectSetTagVisible = useMemo(()=>{
        return jsonTagVisible.hasOwnProperty(tagType)?
                        (jsonTagVisible[tagType].size>0?jsonTagVisible[tagType]:new Set(listPersonJson.map(p => p.id)))
                                :new Set(listPersonJson.map(p => p.id))
    }, [jsonTagVisible,listPersonJson])
    const listPersonJsonShown = useMemo(()=>{
        return listPersonJson.filter((personJson)=> objectSetTagVisible.has(personJson.id))
    }, [listPersonJson,objectSetTagVisible])

    return <Box>
        <HeaderSectionBig text={"Evolution du nombre de tweets dans le temps"}  idName={"evol_nb"}/>
            <TabTime listTimeValue={listTimeValue} indexTimeValue={indexTimeValue}
       onChangeIndex={(index) => setIndexTimeValue(index)} />

        <ErrorBoundary><GraphEvol listPersonJsonShown={listPersonJsonShown} timeTopValue={timeTopValue} evolData={evolData}/></ErrorBoundary>
    </Box>
}

export default  SectionEvolPersons