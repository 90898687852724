import {Tab, TabList, Tabs} from "@chakra-ui/react";
import React from "react";

const TabTime = ({listTimeValue,indexTimeValue,onChangeIndex})=>{
    return  <Tabs size='md' variant='line' colorScheme="primary"
                  onChange={(index) => onChangeIndex(index)}   defaultIndex={indexTimeValue}>
      <TabList>
          {listTimeValue.map((v) => <Tab key={v["id"]}>{v["n"]}</Tab>)}
      </TabList>

    </Tabs>
}

export default TabTime