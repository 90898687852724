import {Box, Flex, Heading} from "@chakra-ui/react";
import React, {useContext, useEffect, useMemo, useState} from "react";
import { motion} from "framer-motion";


const TopItemHeader = ({rank,jsonItem,isOpen,typeItem})=>{

    const content = useMemo( () => {
        switch (typeItem) {
            case "vocab":
                return <Box mt={"-5px"} zIndex={1} fontSize={["14px","17px","20px"]}
                 fontWeight={"bold"} letterSpacing={["1.4px","1.8px","2.5px"]} fontFamily={"body"} textTransform={"uppercase"}>
                {jsonItem.n}</Box>
            case "tweet":
                return <Box zIndex={1}></Box>
        }
    },[typeItem,jsonItem])

    return content
}


export default TopItemHeader