import {Avatar, Box, Flex} from "@chakra-ui/react"
import React, {useMemo, useState} from "react";
import {getNameFromTagId, getTypeFromTagId, getUrlImgFromTagId} from "./tools";
import {motion} from "framer-motion";
import {SColorBG, SColorGreen, SColorRed} from "../../gatsby-plugin-theme-ui-old";

const MotionBox = motion(Box)
const MotionFlex = motion(Flex)
const MotionAvatar = motion(Avatar)

export const TagCard = ({tagId,tagProp,diffNormal})=>{

    const urlImg =  getUrlImgFromTagId(tagId,50)
    const nameTag =getNameFromTagId(tagId)
    const typeCard = getTypeFromTagId(tagId)

    const [isHover,setIsHover] = useState(false)

    const diffValueShowed = useMemo(()=>{

        if (diffNormal >100){
            return <div sx={{color:SColorGreen[500]}}> +{diffNormal -100 }%</div>
        }else if(diffNormal <100){
            return <div sx={{color:SColorRed[500]}}> -{(diffNormal -100)*-1 }%</div>
        }
        else{
            return <div> +{diffNormal -100 }%</div>
        }
    }, [diffNormal])

    return <MotionFlex sx={{p:'4px',bg:'white',ml:'8px',mr:'8px',mt:'8px',mb:'4px',borderRadius:'5px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
        justifyContent:'space-between',
    alignItems:'center'}}
    onMouseEnter={()=>setIsHover(true)} onMouseLeave={()=>setIsHover(false)}
       animate={isHover?"hover":"default"}
       variants={{hover:{},default:{} }}>
        <Box sx={{width:'50px'}}><MotionAvatar src={urlImg}
                                                variants={{hover:{filter:'grayscale(50%)'},default:{filter:'grayscale(100%)'} }}
                                                width={50} p={0}/></Box>
        <MotionBox sx={{fontWeight:'bold',width:'calc( 100% - 170px)', textAlign:'center',fontSize:15,p:'4px'}}
         variants={{hover:{opacity:0.60,x:-3},default:{opacity:1,x:0} }}
        >{nameTag}</MotionBox>

            <MotionBox sx={{width: '120px',borderLeftStyle:'solid',borderLeftWidth:'1px',
            borderLeftColor:'bg700'}}
            variants={{hover:{x:-5,y:-2,scale:1.03},default:{x:0,y:0,scale:1} }}
            >
                <Flex sx={{justifyContent:'center',alignItems:'center'}}>
                    <Box sx={{fontWeight:'bold', p:'4px', width:'60px',textAlign:'center'}}>{tagProp}%</Box>
                    <Box sx={{fontSize:'10px',color:'gray.500',lineHeight:1,width:'50px'}}>des<br/>tweets</Box>
                </Flex>
                <Flex sx={{justifyContent:'center',alignItems:'center'}}>
                    <Box sx={{fontWeight:'bold', p:'4px', width:'60px' ,textAlign:'center',fontSize:14}}>{diffValueShowed}</Box>
                    <Box sx={{fontSize:'10px',color:'gray.500',lineHeight:1,width:'50px'}}>par rapport<br/>à la normale</Box>
                </Flex>
            </MotionBox>

    </MotionFlex>
}




