import {Box, Flex, Heading} from "@chakra-ui/react";
import React, {useMemo} from "react";
import {TweetToken} from "../../tweet/card/TweetToken";


const CDI_tweet = ({data}) =>{

    return <Box>
        <TweetItem tweetFrontData={data} isOpen={false} listHighlight={[]} rank={1}/>
    </Box>
}

const TweetItem = ({tweetFrontData,rank})=>{
    const {id,aun,mu,nbr} = tweetFrontData
    const dateTweet = new Date(tweetFrontData.date.replace(/-/g, "/"))
    const dateTweetStr = useMemo(()=>{
        return dateTweet.toLocaleDateString("fr-FR",  { year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit',minute:'2-digit' })
    },[dateTweet])
    const listToken = tweetFrontData.lt

    const authorUserName = aun?aun:"unknown"
    return <Box sx={{}}>
                    <Box fontSize={"22px"} fontWeight={"bold"} color={"text.200"} p={1} pr={2}>#{rank}.</Box>
            <Box sx={{display: 'flex',pl:2,pr:2,lineHeight: 1.1, justifyContent: 'space-between'}}>
                <Box>
                    <Box sx={{fontWeight: 'bold'}}>@{authorUserName}</Box>
                    <Box sx={{color: 'grey', fontSize: 12}}>{dateTweetStr}</Box>
                </Box>
                <Box sx={{textAlign: 'center'}}>
                    <Box ><Box as={"span"} sx={{fontWeight:"bold"}}>{nbr} </Box>
                        <Box as={"span"} sx={{fontWeight:"body"}}>Retweets</Box></Box>
                    <Box sx={{color: 'grey', fontSize: 12 , textAlign:'right'}}>(4 dernières heures)</Box>
                </Box>
            </Box>
            <Flex minHeight={"50px"} flexDirection={"column"} justifyContent={"center"} sx={{
            m: 1,ml:2,mr:2,borderRadius:5,borderColor:'text.50',borderWidth:'1px',borderStyle:'solid',bg:"white"}
            }>
           <Box sx={{lineHeight: 1.2, fontWeight:"body",
                p:1,pl:2, fontSize: '18px'}}>{listToken.map((token, i) => <TweetToken key={i}
                                                                                                     token={token}
                                                                                                listHighlight={[]}/>)}
           </Box>
            </Flex>
            </Box>
}

export default CDI_tweet