import {Box, Flex, Image, Spinner} from "@chakra-ui/react";

import React  from "react";
import {getUrlImgWordCloudHier} from "../../api/getData";
import {motion} from "framer-motion";
const MotionBox = motion(Box)
const CDI_hier = ({data}) =>{
    const {hierId} = data
    // TODO ONLY SHOW IMG
    const Loader = <Box width={'100%'} pb={"100%"} height={0} position={"relative"}>
            <Flex justifyContent={"center"} alignItems={"center"} width={'100%'} position={"absolute"} height={"100%"}><Spinner  size='xl' color="text.100" /></Flex></Box>
    const urlImg = getUrlImgWordCloudHier(hierId)
    const canShowImg = (hierId != null)
    return <MotionBox id={"wc_hier"} whileHover={{opacity:1}} maxWidth={"100%"} width={"800px"} p={1} bg={"#232119"} opacity={0.9} borderRadius={"4px"} position={"relative"}>
        {canShowImg && <Image src={urlImg} alt='vocabulaire politique de la veille' fallback={Loader} />}
        {!canShowImg && Loader}
        </MotionBox>
}

export default CDI_hier