import {Box, Flex, Heading} from "@chakra-ui/react";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {AnimatePresence, motion, } from "framer-motion";
import TopItemContent from "./TopItemContent";
import {CloseIcon} from "@chakra-ui/icons";
import {LOG} from "../../tools";
import TopItemHeader from "./TopItemHeader";
import {sendEvent} from "../../tools/analytics";

const MotionBox = motion(Box)
const MotionFlex = motion(Flex)
const TopItem = ({rank,jsonItem,typeItem,timeTopValue})=>{

    const [isHover, setIsHover] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    useEffect(()=>{setIsOpen(false)},[timeTopValue])
    const canOpen = useMemo(()=>{
        return true
    },[typeItem])
    const sxCard = useMemo(()=>{
        switch(typeItem){
            case "tweet":
                return {minWidth:"300px"}
            default:
                return {}
        }
    },[typeItem])


    return     <MotionBox

        animate={isOpen?"open":"close"}
        position={"relative"}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0,display:'none' }}
                transition={{ duration: 0.2, type: "tween" }}
                variants={{open: {width:"100%",opacity:1},
                close: {width:"auto",opacity:1,transition:{delay:0.1}}}} pt={["8px","15px"]}
    sx={sxCard} mt={0}
    >
        <MotionBox borderRadius={"2px"}
           onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}
        p={1} pt={0} m={[1]} bg={"white"} boxShadow={"cardItem"} borderColor={"text.100"} borderStyle={"solid"} borderWidth={rank==1?"1px":0}
       animate={(isHover) ? "hover" : "default"}
        variants={{
                hover: {y: -2},
                default: {y:0}
            }}
                   height={"100%"}
        cursor={isOpen?"default":"pointer"} position={"relative"}
                   onClick={()=> {
                       if (canOpen && !isOpen) {
                           setIsOpen(true)
                           sendEvent("click", { "rank":rank,"type":typeItem })
                       }
                   }}t
        >
        <Flex flexDirection={"column"} minHeight={"20px"} minWidth={"50px"}>
            <Flex justifyContent={"center"} alignItems={"center"} mt={["-10px","-15px"]}>
                <Flex
                    _before={{position:"absolute",content:'""',width:"150%",
                                height:"28px",left:"-25%",top:["10px","15px"],bg:"white"}}

                    position={"relative"}
                    boxShadow={'cardItem'}
                    flexDirection={"column"} justifyContent={"center"}
                    bg="white" fontSize={["16px",20]} borderRadius={"50%"} width={["30px","35px"]} height={["30px","35px"]}
                     borderColor={"text.100"} borderStyle={"solid"} borderWidth={rank==1?"1px":0}
                textAlign={"center"}>
                    <Box zIndex={2} color={"text.100"}>#{rank}</Box>
                </Flex>
            </Flex>
            <Flex justifyContent={"center"}>
                <TopItemHeader jsonItem={jsonItem} typeItem={typeItem}/>
            </Flex>
        <TopItemContent rank={rank} isOpen={isOpen} jsonItem={jsonItem} typeItem={typeItem} timeTopValue={timeTopValue}/>
        </Flex>

<AnimatePresence>
            {(isOpen) &&
            <MotionBox onClick={()=>setIsOpen(false)}
                       zIndex={2}
                       sx={{position:'absolute',top:0,right: '10px',cursor:'pointer',color:'text.100'}}
                       initial={{ opacity: 0,y:-5 }}
                       animate={{ opacity: 1,y:0 }}
                       exit={{ opacity: 0,y:-5 }}
            ><CloseIcon/></MotionBox>}
        </AnimatePresence>

    </MotionBox>

    </MotionBox>
}
/*
<Flex justifyContent={"flex-start"} alignItems={"center"}>
                <Box fontSize={20}>#{rank}</Box>
                <Box pl={2}>
                    <Box color="text.300">{nbTweet}</Box>
                    <Box fontSize={"xs"} lineHeight={0} color={"text.100"}>tweets</Box>
                </Box>
            </Flex>
 */
export default  TopItem