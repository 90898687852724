
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Box} from "@chakra-ui/react";
import {Line} from "react-chartjs-2";
import theme from "../../../../@chakra-ui/gatsby-plugin/theme";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-date-fns';
import {setGradientColor, movingAvg, getColorFromTagIdStr, getColorFromNumColor} from "../../../graph/tools";
import {getNameFromTagId} from "../../../tag/tools";
import {LOG} from "../../../tools";

const LineEvolTopicByPerson = ({tagIdTopic,listLabel,jsonLineTags,objectSetTagVisiblePerson})=> {
    const ref = useRef();

    const [datasets,setDatasets] = useState([])
    const [msgNote, setMsgNote] = useState("")
    const nameTopic = getNameFromTagId(tagIdTopic)
    useEffect(()=>{

        let datasetsEvol = []
        let noteTmp = null
        let numTag = 0
        for (const [tagId, listValueThisTagId] of Object.entries(jsonLineTags)) {
            const isTagIdTopic =( tagId == tagIdTopic)
            if (objectSetTagVisiblePerson.has(tagId) || isTagIdTopic){

                const colorG = isTagIdTopic?theme.colors.gray :getColorFromNumColor(numTag)
                const order = isTagIdTopic?0:numTag+1
            numTag += 1
            const dataAvg = movingAvg(listValueThisTagId, 3, 3)
            datasetsEvol.push({
                    type: 'line',
                    data: dataAvg,
                    label: isTagIdTopic?`${getNameFromTagId(tagId)} - moyenne`:getNameFromTagId(tagId),
                    lineTension: 0.4, order: order, borderWidth: 3,
                    pointRadius: 0,
                    borderDash:isTagIdTopic?[3,3]:[0,0],
                    borderColor: isTagIdTopic?colorG[500]:colorG[200],
                    backgroundColor: isTagIdTopic?'transparent':setGradientColor(ref.current.canvas, colorG[200], 500),
                    fill: true,
                    datalabels: {
                        align: 'right',
                        anchor: 'end',
                        display: function (context) {
                            return (context.dataIndex === context.dataset.data.length - 1);
                        }
                    }
                }
            )
            if (noteTmp == null) {
                noteTmp = `Ces derniers jours, la thématique ${nameTopic} a concerné ${dataAvg[dataAvg.length - 1]}% de l'activité politique concernant ${getNameFromTagId(tagId)} sur twitter`
            }
        }
        }
        setMsgNote(noteTmp)
        setDatasets(datasetsEvol)
    }, [jsonLineTags,objectSetTagVisiblePerson])

    const data= (canvas) =>{
        return {
            labels: listLabel,
            datasets:datasets
        }
    }
    const options = {responsive: true,
    maintainAspectRatio:false,
    scales: {
      x:{grid: {
                display:false,
                    drawBorder: true,
            },type: 'time',
        distribution: 'linear',
          time:{
          unit:"day",
              displayFormats:{day:"dd"}
          },
          ticks: {
      autoSkip: false,
      maxRotation: 0,
      major: {
        enabled: true
      },
      // color: function(context) {
      //   return context.tick && context.tick.major ? '#FF0000' : 'rgba(0,0,0,0.1)';
      // },
      font: function(context) {
        if (context.tick && context.tick.major) {
            return {
                weight: 'bold',
            }
        }}
      }

            },
        y: {
      display: true,

        ticks: {  beginAtZero: false, display: true     },
        grid: {  display:false,   drawBorder: true,  },
      title: {    display: false}
    }
    },
        plugins: {
        title:{display:true,text:`Evolution du poids de la thématique ${nameTopic} en fonction des personnalités politiques`},
        tooltip:{
            enabled:true,
            intersect:false,
            mode:'nearest',
            interaction: {
            mode: 'index',
                axis:'x'
        },
            callbacks: {
                title: function(tooltipItem, data) {
                    return  `Poids de la thématique ${tooltipItem[0].label}`
                },
            }

        },

        datalabels: {
            display:false,

         backgroundColor: function(context) {
          return context.dataset.borderColor;
        },
        borderRadius: 4,
        color: 'white',
        font: {
          weight: 'bold'
        },
        formatter: Math.round(2),
        padding: 2

        },
        legend:{
            position:'bottom',
        labels:{
        filter: function(item, chart) {
          return true;
        }
        }}

        },
            layout:{
        padding:{
      left: 0,right: 50,top: 50,bottom: 10,
    }
        }
    }

    return <><Box width={"100%"} height={500} pb={"5px"}>
        <Line ref={ref} data={data} options={options} plugins={[ChartDataLabels]}
        />
    </Box><Box fontStyle={"italic"} color={"text.200"}>Lecture : {msgNote}</Box></>
}


export default LineEvolTopicByPerson