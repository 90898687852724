
import React from "react";
import {Box} from "@chakra-ui/react";
import VocabItem from "./VocabItem";
import {LOG} from "../../../tools";

const DetailByVocab = ({jsonContentByVocabtag}) =>{
    const listVocabId = Object.keys(jsonContentByVocabtag)

    return <Box>
        {listVocabId.map((v_id,i) => <VocabItem key={v_id} tagId={v_id} numItem={i}
                                                propMean = {jsonContentByVocabtag[v_id]["p"]}
                                                diffToRef = {jsonContentByVocabtag[v_id]["d"]}
                                                listValue={jsonContentByVocabtag[v_id]["l"]}/> )}
    </Box>
}

export default DetailByVocab