
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Box} from "@chakra-ui/react";
import {Line} from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-date-fns';
import {hexToRGB} from "../../tools";
import {getNameFromTagId} from "../../tag/tools";
import {getColorFromTagIdStr, movingAvg} from "../../graph/tools";
const setGradientColor = (canvas, color,heightFill) => {
    if (!color) return;
    const ctx = canvas.getContext("2d");
    //const gradient = ctx.createLinearGradient(0, 100, 900, 195);
    const gradient = ctx.createLinearGradient(0, 0, 0, heightFill);
    gradient.addColorStop(0, hexToRGB(color,0.9 ));
    gradient.addColorStop(0.7, hexToRGB(color,0.01 ));
    //gradient.addColorStop(0.5, color[300]);
    gradient.addColorStop(1, 'rgba(244, 238, 235,0)');
    return gradient;
  };

const LineEvolTopics = ({listLabel,jsonLineTags})=> {
    const ref = useRef();

    const [datasets,setDatasets] = useState([])
    const [msgNote, setMsgNote] = useState("")
    useEffect(()=>{

        let datasetsEvol = []
        let noteTmp = null
        for (const [tagId, listValueThisTagId] of Object.entries(jsonLineTags)) {
            const colorG = getColorFromTagIdStr(tagId)
        const dataAvg = movingAvg(listValueThisTagId, 3, 3)
        datasetsEvol.push({
             type:'line',
        data: dataAvg,
        label: getNameFromTagId(tagId),
        lineTension:0.4,order:0,borderWidth:3,
        pointRadius:0,
        borderColor: colorG[200],
            backgroundColor:setGradientColor(ref.current.canvas,colorG[200],500),
          fill:true,
            datalabels: {
                align: 'right',
        anchor: 'end',
            display: function (context){  return (context.dataIndex === context.dataset.data.length - 1); }
        }
    }
            )
            if(noteTmp==null){
                noteTmp = `Ces derniers jours, la thématique ${getNameFromTagId(tagId)} a concerné ${dataAvg[dataAvg.length -1]}% de l'activité politique sur twitter`
            }

        }
        setMsgNote(noteTmp)
        setDatasets(datasetsEvol)
    }, [jsonLineTags])

    const data= (canvas) =>{
        return {
            labels: listLabel,
            datasets:datasets
        }
    }
    const options = {responsive: true,
    maintainAspectRatio:false,
    scales: {
      x:{grid: {
                display:false,
                    drawBorder: true,
            },type: 'time',
        distribution: 'linear',
          time:{
          unit:"day",
              displayFormats:{day:"dd"}
          },
          ticks: {
      autoSkip: false,
      maxRotation: 0,
      major: {
        enabled: true
      },
      // color: function(context) {
      //   return context.tick && context.tick.major ? '#FF0000' : 'rgba(0,0,0,0.1)';
      // },
      font: function(context) {
        if (context.tick && context.tick.major) {
            return {
                weight: 'bold',
            }
        }}
      }

            },
        y: {
      display: true,

        ticks: {  beginAtZero: false, display: true     },
        grid: {  display:false,   drawBorder: true,  },
      title: {    display: true}
    }
    },
        plugins: {

        tooltip:{
            enabled:true,
            intersect:false,
            mode:'nearest',
            interaction: {
            mode: 'index',
                axis:'x'
        },
            callbacks: {
                title: function(tooltipItem, data) {
                    return  `Poids de la thématique ${tooltipItem[0].label}`
                },
            }

        },

        datalabels: {
            display:false,

         backgroundColor: function(context) {
          return context.dataset.borderColor;
        },
        borderRadius: 4,
        color: 'white',
        font: {
          weight: 'bold'
        },
        formatter: Math.round(2),
        padding: 2

        },

        legend:{
        labels:{
        filter: function(item, chart) {
          return !item.text.includes('moyenne');
        }
        }}

        },
            layout:{
        padding:{
      left: 0,right: 50,top: 50,bottom: 10,
    }
        }
    }

    return <Box width={"100%"} height={500} pb={"50px"}>
        <Line ref={ref} data={data} options={options} plugins={[ChartDataLabels]}
        />
        <Box fontStyle={"italic"} color={"text.200"}>Lecture : {msgNote}</Box>
    </Box>
}


export default LineEvolTopics