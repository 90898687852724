import {Box, Flex} from "@chakra-ui/react";

import React, {useContext, useEffect, useMemo, useState} from "react";
import {motion} from "framer-motion";
import DataLoadContext from "../../../../context/DataLoadContext";
import {getDataSummaryVocabs, getDataSummaryVocabsCard} from "../../../api/getData";
import {addMinutes, LOG} from "../../../tools";
import LineEvolTopicByPerson from "../../topics/card/LineEvolTopicByPerson";
import PersonalizedContentContext from "../../../../context/PersonalizedContent";
import {LIST_ALL_PERSON} from "../../../tag/tools";
import {ChevronUpIcon} from "@chakra-ui/icons";
import theme from "../../../../@chakra-ui/gatsby-plugin/theme";
import WordCloudTopic from "../../topics/card/WordCloudTopic";

const MotionBox = motion(Box)
const MotionFlex = motion(Flex)
const VocabItemContent = ({tagId,closeContent})=>{
    const {lastUpdate} = useContext(DataLoadContext);
    const lastUpdateTagTopics = lastUpdate.hasOwnProperty("TagTopics")?lastUpdate["TagTopics"]:null

    const [jsonLineTags,setJsonLineTags] = useState({})
    const [listLabel,setListLabel] = useState([])

    const {jsonTagVisible} = useContext(PersonalizedContentContext);
    const tagType = "P"
    const objectSetTagVisiblePerson = useMemo(()=>{
        return jsonTagVisible.hasOwnProperty(tagType)?
                        (jsonTagVisible[tagType].size>0?jsonTagVisible[tagType]:new Set(LIST_ALL_PERSON.map(p => p.id)))
                                :new Set(LIST_ALL_PERSON.map(p => p.id))
    }, [jsonTagVisible])


    useEffect(() => {
        if(lastUpdateTagTopics!=null){
            getDataSummaryVocabsCard(tagId ,lastUpdateTagTopics).then((d) =>{
                /* { d: {1:{d:[]}, 2:... },  sd: "2021xxxxx"} */
            LOG("getDataSummaryVocabsCard",d)
            setJsonLineTags(d["d"])
            const startDate = new Date(d["sd"])
                const stepWindow = 60*24
            const listTagId = Object.keys(d["d"])
              const tag_id_test =  listTagId[0]
              const nbPoints = d["d"][tag_id_test].length
                let list_label = []
            for (const i of Array(nbPoints).keys()) {
              list_label.push(addMinutes(startDate,i*stepWindow)
                 )
             }
            setListLabel(list_label)
    });
        }

  }, [lastUpdateTagTopics]);
    LOG ("RENDER VocabItemContent")
    return <MotionBox    variants={{
        open:{height:'auto',},
        close:{height:0}
    }}>
        <LineEvolTopicByPerson listLabel={listLabel}
                               tagIdTopic={tagId}
           objectSetTagVisiblePerson={objectSetTagVisiblePerson}
            jsonLineTags={jsonLineTags}/>
        <WordCloudTopic tagIdTopic={tagId}/>
           <Flex justifyContent={"center"} pl={"15px"} pr={"15px"} borderBottomColor={"text.50"} borderBottomStyle={"solid"} borderBottomWidth={"1px"}>
               <Flex flexDirection={"column"} justifyContent={"center"} alignItems={"center"} onClick={()=> closeContent()}  cursor={"pointer"} mb={"-8px"}>
                   <Box fontSize={"12px"} color={"text.100"}>RÉDUIRE</Box><ChevronUpIcon bg={"bg.500"} color={"text.100"}/></Flex></Flex>
    </MotionBox>
}


export default VocabItemContent