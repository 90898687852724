import * as React from "react"
import {useEffect, useMemo, useState} from "react";
import {TwitterTweetEmbed} from "react-twitter-embed";

import {AnimatePresence, motion} from "framer-motion"
import {Box, Flex, Spinner, useMediaQuery} from "@chakra-ui/react";
import {ViewIcon} from "@chakra-ui/icons"
import {SColorBG} from "../../../../gatsby-plugin-theme-ui-old";
import {TweetToken} from "../../../tweet/card/TweetToken";
import {LOG} from "../../../tools";
const MotionBox = motion(Box)
const options = { year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit',minute:'2-digit' };
export const TicTweetContent = ({tweetFrontData,listHighlight,isOpen})=> {
    const {id,aun,mu,nbr} = tweetFrontData

    //The pattern yyyy-MM-dd isn't an officially supported format for Date
        const dateTweet = new Date(tweetFrontData.date.replace(/-/g, "/"))

    const dateTweetStr = useMemo(()=>{
        return dateTweet.toLocaleDateString("fr-FR", options)
    },[dateTweet])

    const [tweetIsMissing,setTweetIsMissing] = useState(false)
    const [tweetIsLoaded,setTweetIsLoaded] = useState(false)
        const [isLowerThan580] = useMediaQuery("(max-width: 580px)")
    const listToken = tweetFrontData.lt
    const authorUserName = aun?aun:"unknown"
    const showEmbed = isOpen
    useEffect(()=>{
        if(isOpen ==false){
            setTweetIsLoaded(false)
        }
    },[isOpen])
    return <MotionBox sx={{maxWidth:'100%', minWidth:280,
         borderRadius:'3px',p:0,pb:0 }}
                animate={isOpen?"hover":"default"}

    >
        <Box sx={{lineHeight: 1}}>
            <Box sx={{display: 'flex',pl:1,pr:1, justifyContent: 'space-between'}}>
                <div>
                    <Box sx={{fontWeight: 'bold'}}>@{authorUserName}</Box>
                    <Box sx={{color: 'grey', fontSize: 12}}>{dateTweetStr}</Box>
                </div>
                <Box sx={{textAlign: 'center'}}>
                    <Box sx={{fontWeight: 'bold'}}>{nbr}</Box>
                    <Box sx={{color: 'grey', fontSize: 12}}>retweets</Box>
                </Box>
            </Box>
            {!showEmbed &&
            <Box sx={{lineHeight: 1.2, m: 1,borderRadius:5,borderColor:'text.50',borderWidth:'1px',borderStyle:'solid',
                p:1,pl:2, fontSize: 15,bg:"white"}}>{listToken.map((token, i) => <TweetToken key={i}
                                                                                                     token={token}
                                                                                                 listHighlight={listHighlight}/>)}</Box>
            }
            </Box>

            <motion.div
            sx={{minHeight:150}}
            initial={{ height:150}}
            animate={{height:'auto'}}
        >
                <AnimatePresence>
        {showEmbed &&

        <TwitterTweetEmbed
            tweetId={`${id}`}
            options={{"theme": "blue", "width": '300', 'align': 'center'}}
            onLoad={tweet => {
                if(!tweet){
                    setTweetIsMissing(true);
                }
                setTweetIsLoaded(true)
            }}
        />
       }
                    {(!tweetIsLoaded & showEmbed) &&
          <Flex justifyContent={"center"} flexDirection={"column"} alignItems={"center"} height={200}>
            <Spinner color="text.100" />
        </Flex>

                    }
       </AnimatePresence>
        </motion.div>
        <Box sx={{position:'relative',height:'20px'}}>
            {(showEmbed && !tweetIsLoaded) && <Box width={"300px"}/>}
            <AnimatePresence>
                {(!showEmbed && (isOpen | isLowerThan580 ))   &&
                <MotionBox
                            sx={{position:'absolute',right: '100px',cursor:'pointer',color:'text.100'}}
                            initial={{ opacity: 0,y:-12 }}
                            animate={{ opacity: 1,y:-5 }}
                            exit={{ opacity: 0,y:-12 }}
                ><ViewIcon mr={1} mb={'1px'} color={"text.50"}/>Afficher le tweet</MotionBox>}
            </AnimatePresence>

        </Box>
    </MotionBox>
}
